import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import './components.scss'

export default function LanguageDropdown({setLang,dropMenu}) {
    return (
        <Box className={'lang'}>
            <FormControl >
                <NativeSelect
                    defaultValue={window.localStorage.getItem('lang')}
                    style={{marginLeft:dropMenu?'20px':null}}
                    inputProps={{
                        name: 'age',
                        id: 'uncontrolled-native',
                    }}
                    onChange={(e)=>{
                        setLang(e.target.value)
                        window.localStorage.setItem('lang',e.target.value)
                    }}
                >
                    <option value='am'>AM</option>
                    <option value='ru'>RU</option>
                    <option value='en'>EN</option>
                </NativeSelect>
            </FormControl>
        </Box>
    );
}