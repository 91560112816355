import React from "react"
import "./components.scss"

const Footer = ({language}) => {
    return (
        <div className="Footer">
            <div className="tipsLogo">
                <img src={require("./imgs/TipsLogo.png")} alt="tipsLogoImg" />
                <a href="/terms" target="_blank">
                    {language.block12Text1}
                </a>
                <a href="/privacy" target="_blank">
                    {language.block12Text2}
                </a>
            </div>
            <div className="aboutUs">
                <h1>
                    {language.block12Text3}
                </h1>
                <a href="#advantages">
                    {language.block12Text4}
                </a>
                <a href="#howDoesItWork">
                    {language.block12Text5}
                </a>
                <a href="#OftenGivenQuestions">
                    {language.block12Text6}
                </a>
            </div>
            <div className="forCooperation">
                <h1>
                    {language.block12Text7}
                </h1>
                <p>16192 Coastal Highway</p>
                <p>Lewes, Delaware, 19958</p>
                <p>United States</p>
                <a href={`tel:${"+1 305 517 7556"}`}>
                    +1 305 517 7556
                </a>
                <a href={`mailto:${"info@tips.am"}`}>
                    info@tips.am
                </a>
            </div>
            <div className="feedBackFooter">
                <h1>
                    {language.block12Text8}
                </h1>
                <a href={`tel:${"+374 44 12 35 03"}`}>
                    +374 44 12 35 03
                </a>
                <a href={`mailto:${"support@tips.am"}`}>
                    support@tips.am
                </a>
                <div>
                    <a href="https://www.facebook.com/tips.armenia" target="_blank">
                        <i style={{
                            fontSize: '25px',
                            marginRight: '10px'
                        }} className="fa fa-facebook" aria-hidden="true"></i>
                    </a>
                    <a href="https://www.instagram.com/tips.armenia" target="_blank">
                        <i style={{
                            fontSize: '28px'
                        }} className="fa fa-instagram" aria-hidden="true"></i>
                    </a>
                </div>
            </div>
        </div>
    )
};

export default Footer;
