import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useState } from "react";
import tipsAM from "./imgs/tipsAm.svg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SuccessfullySentPopUp({ opens, text, button,btnText }) {
  const [open, setOpen] = useState(opens);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Slide in alert dialog
      </Button> */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <img
          style={{ width: "100px", height: "auto", margin: "25px" }}
          src={tipsAM}
          alt="Tips.am"
        />
        <DialogContent>
          <p style={{ color: "green" }}>{text}</p>
        </DialogContent>
        <DialogActions>
          {button && (
            <button
              style={{
                width: "120px",
                height: "30px",
                color: "white",
                borderRadius: "8px",
                border: "none",
                background: "#12D51A",
              }}
              onClick={handleClose}
            >
              {btnText}
            </button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
