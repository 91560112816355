import React, {useEffect, useState} from "react"
import Header from "./header";
import Tip from "./tip";
import HowDoesItWork from "./howDoesItWork"; 
import HowToPay from "./howToPay";
import FreeConnect from "./freeConnect";
import OurPartners from "./ourPartners";
import PartnersReview from "./partnersReview";
import SubscriptionEmail from "./subscriptionEmail";
import OftenGivenQuestions from "./oftenGivenQuestions";
import FeedBack from "./feedBack";
import Footer from "./footer";
import '../App.css';
import Advantages from "./advantages";
import {Texts} from "../utils/Texts";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";

const MainPages = () => {
    const [lang,setLang] = useState(window.localStorage.getItem('lang'))
    const [params,setParams] = useSearchParams()
    const language = lang==='en'?Texts.en:lang==='ru'?Texts.ru:Texts.am
    useEffect(()=>{
        if(lang==='am'){
            setParams({})
        }else
        setParams({language:lang})
    },[lang])
    return (
    <div>
      <Header setLang={e=>setLang(e)} lang={lang} language={language}/>
      <Tip language={language}/>
      <HowDoesItWork language={language}/>
      <Advantages language={language}/>
      <HowToPay language={language}/>
      <FreeConnect language={language}/>
      <OurPartners language={language}/>
      <PartnersReview language={language}/>
      <SubscriptionEmail language={language}/>
      <OftenGivenQuestions language={language}/>
      <FeedBack language={language} />
      <Footer language={language}/>
    </div>
  )
};

export default MainPages;
