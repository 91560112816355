import React from "react"
import "./components.scss"
import FeedBackDialog from "./feedBackDialog";

export default function Advantages ({language})  {
  return (
    <div id="advantages" className="advantages">
      <h1>
        {language.block3Text1}

      </h1>
      <div className="advBox">
        <div className="advDiv">
          <div>
            <img src={require("./imgs/advant1.png")} alt="image" />
            <p>
              {language.block3Text2}

            </p>
          </div>
          <div>
            <img style={{ width: '90px' }} src={require("./imgs/advant4.png")} alt="image" />
            <p>
              {language.block3Text6}

            </p>
          </div>
        </div>

        <div className="advDiv">
          <div>
            <img src={require("./imgs/advant2.png")} alt="image" />
            <p>
              {language.block3Text3}
            </p>
          </div>
          <div>
            <img style={{ width: '90px' }} src={require("./imgs/advant5.png")} alt="image" />
            <p>
              {language.block3Text7}
            </p>
          </div>
        </div>

        <div className="advDiv">
          <div>
            <img src={require("./imgs/advant3.png")} alt="image" />
            <p>
              {language.block3Text4}
            </p>
          </div>
          <div>
            <img style={{ width: '110px' }} src={require("./imgs/advant6.png")} alt="image" />
            <p>
              {language.block3Text8}

            </p>
          </div>
        </div>
        <div className="advDiv1">
          <div className="connection">
            <img src={require("./imgs/advantDr.png")} alt="image" />
            <h1 style={{textAlign:'center'}}>
              {language.block3Text5.a}
            </h1>
            <p>
              {language.block3Text5.b}

            </p>
            <FeedBackDialog text={language.block1btn1} language={language} />
          </div>
          <div className="lang">
            <div style={{ display: 'flex' }}>
              <img src={require("./imgs/UK.png")} alt="UK" />
              <img src={require("./imgs/AM.png")} alt="AM" />
              <img src={require("./imgs/RU.png")} alt="RU" />
            </div>
            <p>
              {language.block3Text9}
            </p>
          </div>

        </div>
      </div>
    </div>
  )
};