import React from "react"
import "./components.scss"
import FeedBackDialog from "./feedBackDialog";

const FreeConnect = ({language}) => {
  const connection="320+"
  const tip= 1420
  const tipCount="12360+"
  const waiter= "1310+"
  return (
    <div className="FreeConnect">
      <div className="sums">
        <div>
          <h1>
            {connection}
          </h1>
          <p>
            {language.block5Text1}
          </p>
        </div>
        <div>
          <h1>
            {tip}
          </h1>
          <p>
            {language.block5Text2}
          </p>
        </div>
        <div>
          <h1>
            {tipCount}
          </h1>
          <p>
            {language.block5Text3}
          </p>
        </div>
        <div>
          <h1>
            {waiter}
          </h1>
          <p>
            {language.block5Text4}
          </p>
        </div>
      </div>

      <div className="freeCnct">
        <h1>
          {language.block5Text5.a}
        </h1>
        <p>
          {language.block5Text5.b}
        </p>
        <FeedBackDialog text={language.block1btn1} language={language}/>
      </div>

    </div>
  )
};

export default FreeConnect;
