import React from "react"
import "./components.scss"

const HowToPay = ({language}) => {
    return (
        <div className="HowToPay">
            <h1>
                {language.block4Text1}

            </h1>
            <div>
                <div>
                    <img src={require("./imgs/HowToPay1.png")} alt="Image" />
                    <p>
                        {language.block4Text2}

                    </p>
                </div>
                <div>
                    <img src={require("./imgs/HowToPay2.png")} alt="Image" />
                    <p>
                        {language.block4Text3}
                    </p>
                </div>
                <div>
                    <img src={require("./imgs/HowToPay3.png")} alt="Image" />
                    <p>
                        {language.block4Text4}
                    </p>
                </div>
                <div>
                    <img className='borderedImage' src={require("./imgs/HowToPay4.jpg")} alt="Image" style={{
                        borderTopLeftRadius:'30px',
                        borderTopRightRadius:'30px',
                        borderTop:'7px solid #D1CDF9',
                        borderLeft:'6px solid #D1CDF9',
                        borderRight:'6px solid #D1CDF9',
                        height:'307px',
                        outline:'none',
                        boxSizing:'border-box'
                    }}/>
                    <p>
                        {language.block4Text5}
                    </p>
                </div>
            </div>
        </div>
    )
};

export default HowToPay;
