import React, {useState, useEffect, useRef} from 'react';
import "./components.scss"
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import { Divide } from 'hamburger-react'
import tipsLogo from './imgs/tipsAm.svg'

import FeedBackDialog from "./feedBackDialog"
import {Texts} from "../utils/Texts";
import LanguageDropdown from "./LanguageDropdown";

const Header = ({setLang,lang,language}) => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 50);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  useEffect(() => {
    window.addEventListener('resize', closeMenu)
  })
  const closeMenu = () => {
    handleClose()
  }
  const ref = useRef()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  }
  return (
    <header className={visible ? '' : 'hide'}>
      <div>
        <a href="#">
          <img src={tipsLogo} alt='tips.am' />
        </a>
      </div>
      <ul className="navBar">
        <li>
          <a href="#advantages">
            {language.advantages}
          </a>
        </li>
        <li>
          <a href="#howDoesItWork">
            {language.howDoesItWork}
          </a>
        </li>
        <li>
          <a href="#OftenGivenQuestions">
            {language.oftenGivenQuestions}
          </a>
        </li>
        <li className='headerDialog'>
          <FeedBackDialog text={language.block1btn1} language={language} />
        </li>
      </ul>
      <LanguageDropdown setLang={setLang} dropMenu={false}/>
      <div className='hambMenu'>
        <Button
          id="fade-button"
          aria-controls={open ? 'fade-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <Divide toggled={open} toggle={handleClose} />
        </Button>
        <Menu
          id="fade-menu"
          MenuListProps={{
            'aria-labelledby': 'fade-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          <div className='hambMenuDiv' onClick={handleClose}>
            <a style={{ textDecoration: 'none', color: 'black' }} href="#advantages">
              {language.advantages}
            </a>
          </div>
          <div className='hambMenuDiv' onClick={handleClose}>
            <a style={{ textDecoration: 'none', color: 'black' }} href="#howDoesItWork">
              {language.howDoesItWork}

            </a>
          </div>
          <div className='hambMenuDiv' onClick={handleClose}>
            <a style={{ textDecoration: 'none', color: 'black' }} href="#OftenGivenQuestions">
              {language.oftenGivenQuestions}

            </a>
          </div>
          <div className='btn hambMenuDiv'>
              <FeedBackDialog text={language.block11Text1} language={language} />
          </div>
          <LanguageDropdown setLang={setLang} dropMenu={true}/>
        </Menu>
      </div>

    </header>
  )
};

export default Header;