import React from "react"
import "swiper/css/bundle";
import 'swiper/css';
import "./components.scss"


const OurPartners = ({language}) => {
    const partners = [
        { id: 1, imgFront: require("./imgs/partnersLogo/segafredo.png"), imgBack: require("./imgs/partnersLogo/segafredo.png"), cmpName: 'Segafredo', address: `${language.block6Text2} ---`, schedule: `${language.block6Text3}՝ 8:30 - 00:30` },
        { id: 2, imgFront: require("./imgs/partnersLogo/napoletana.jpg"), imgBack: require("./imgs/partnersLogo/napoletana.jpg"), cmpName: 'Napoletana', address: `${language.block6Text2} Բուզանդ 107`, schedule: `${language.block6Text3} 10:00 - 00:00` },
        { id: 3, imgFront: require("./imgs/partnersLogo/karas.jpg"), imgBack: require("./imgs/partnersLogo/karas.jpg"), cmpName: 'Karas', address: `${language.block6Text2} ---`, schedule: `${language.block6Text3} ---` },
        { id: 4, imgFront: require("./imgs/partnersLogo/avocado.png"), imgBack: require("./imgs/partnersLogo/avocado.png"), cmpName: 'Avocado Queen', address: `${language.block6Text2} Սարյան 2/2`, schedule: `${language.block6Text3} 9։00 - 02։00` },
        { id: 5, imgFront: require("./imgs/partnersLogo/julioslogo.png"), imgBack: require("./imgs/partnersLogo/julioslogo.png"), cmpName: 'Julios', address: `${language.block6Text2} Մոսկովյան 35`, schedule: `${language.block6Text3} 9:30 - 00:00` },
        { id: 6, imgFront: require("./imgs/partnersLogo/doc.jpg"), imgBack: require("./imgs/partnersLogo/doc.jpg"), cmpName: 'DOC', address: `${language.block6Text2} Հյուսիսային պողոտա 5`, schedule: `${language.block6Text3} 8:00 - 00:00` },
        { id: 7, imgFront: require("./imgs/partnersLogo/kilika.png"), imgBack: require("./imgs/partnersLogo/kilika.png"), cmpName: 'Kilikia Beer House', address: `${language.block6Text2} Աբովյան 8`, schedule: `${language.block6Text3} 11:00 - 00:00` },
        { id: 8, imgFront: require("./imgs/partnersLogo/vostan.jpg"), imgBack: require("./imgs/partnersLogo/vostan.jpg"), cmpName: 'Vostan Restaurant', address: `${language.block6Text2} Աբովյան 8`, schedule: `${language.block6Text3} 10:00 - 00:00` },
        { id: 9, imgFront: require("./imgs/partnersLogo/malaga.jpg"), imgBack: require("./imgs/partnersLogo/malaga.jpg"), cmpName: 'Malaga', address: `${language.block6Text2} Հյուսիսային պողոտա 8/1`, schedule: `${language.block6Text3} 10:00 - 00:00` },
        { id: 10, imgFront: require("./imgs/partnersLogo/Logo_Paul.png"), imgBack: require("./imgs/partnersLogo/Logo_Paul.png"), cmpName: 'Paul', address: `${language.block6Text2} Աբովյան 8`, schedule: `${language.block6Text3} 8:00 - 23:00` },
        { id: 11, imgFront: require("./imgs/partnersLogo/mayrig.png"), imgBack: require("./imgs/partnersLogo/mayrig.png"), cmpName: 'Mayrig', address: `${language.block6Text2} Ամիրյան 4/6`, schedule: `${language.block6Text3} 10:00 - 00:00` },
        { id: 12, imgFront: require("./imgs/partnersLogo/yakitoria.jpg"), imgBack: require("./imgs/partnersLogo/yakitoria.jpg"), cmpName: 'Yakitoria', address: `${language.block6Text2} Արամի 74`, schedule: `${language.block6Text3} 12:00 - 00:00` },
        { id: 13, imgFront: require("./imgs/partnersLogo/mozzarella.jpg"), imgBack: require("./imgs/partnersLogo/mozzarella.jpg"), cmpName: 'Mozzarela', address: `${language.block6Text2} Արամի 2`, schedule: `${language.block6Text3} 10:00 - 00:00` },
        { id: 14, imgFront: require("./imgs/partnersLogo/sorriso.png"), imgBack: require("./imgs/partnersLogo/sorriso.png"), cmpName: 'Sorisso', address: `${language.block6Text2} ---`, schedule: `${language.block6Text3} 10:00 - 00:00` },
    ]

    return (
        <div className="OurPartners">
            <h1 className="OurPartnersH1">
                {language.block6Text1}
            </h1>
            <div className="partners">
                <div className="mainContainer">
                    {
                        partners.map(({ id, imgBack, imgFront, cmpName, address, schedule }) => {
                            return <div key={id} className="card">
                                <div className="front">
                                    <img style={{
                                        width: 'auto',
                                        height: '70px'
                                    }} src={imgBack} alt="cardBackImg" />
                                </div>
                                <div className="back">
                                    <div>
                                    <img  src={imgFront} alt="cardFrontImg" />
                                    </div>
                                    <h1>
                                        {cmpName}
                                    </h1>
                                    <p>
                                        {address}
                                    </p>
                                    <p>
                                        {schedule}
                                    </p>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
            <div className="partner-ad">
                <div className="PartnerApp">
                    <div className="partnerAppBox1">
                        <h1>
                            {language.block7Text1}
                        </h1>
                        <p>
                            {language.block7Text2}
                        </p>
                        <div>
                            <a href="https://apps.apple.com/us/app/tips-am-for-waiters/id1623231593" target="_blank">
                                <img src={require("./imgs/appStore.png")} alt="tipsAppStore" />
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=am.tip.app" target="_blank">
                                <img src={require("./imgs/playMarket.png")} alt="tipsPlayMarket" />
                            </a>
                        </div>
                    </div>
                    <img src={require("./imgs/TipsApp.png")} alt="tipsApp" />
                </div>
                <div className="ad">
                    <a href="https://www.dinin.am/" target='_blank'>
                        <img src={require('./imgs/ad.webp')} alt="ad" />
                    </a>
                </div>
            </div>
        </div>
    )
};

export default OurPartners;
