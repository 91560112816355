export const Texts = {
    am:{
        advantages:'Առավելություններ',
        howDoesItWork:'Ի՞նչպես է աշխատում',
        oftenGivenQuestions:'Հաճախ տրվող հարցեր',
        block1Text1:'Թեյավճարի կանխիկ չկա՞, Օգտագործիր հեռախոսդ:',
        block1Text2:'Թեյավճար թողնելը սրճարաններում եւ ռեստորաններում դարձել է ավելի հեշտ քան երբևէ: Պարզապես սկանավորելով QR կոդը հայտնեք Ձեր շնորհակալությունը մատուցողին մեկ ակնթարթում:',
        block1btn1:'Միանալ հիմա',
        block1btn2:'Դիտել տեսանյութը',
        block2Text1:'Ի՞նչպես է այն աշխատում',
        block2btn1:'Հյուր',
        block2Guest1:'Սկանավորե՛ք մատուցողի կողմից տրամադրված QR կոդը, որը կուղղորդի Ձեզ վճարման էջ: Գրանցվելու կամ ներբեռնելու կարիք չկա:',
        block2Guest2:'Նշե՛ք կամ ընտրե՛ք Ձեր նախընտրած թեյավճարի չափը և թողե՛ք մեկնաբանություն մատուցողին կամ ռեստորանին, ծառայության որակը էլ ավելի լավը դարձնելու համար:',
        block2Guest3:'Ընտրե՛ք վճարման աղբյուրը։ Վճարել կոճակը սեղմելով, ձեզ կառաջարկվի վճարման 4 եղանակ՝ Բանկային քարտով, Ռուսական բոլոր բանկային քարտերով, Իդրամ-ով և Yoomoney-ով',
        block2btn2:'Աշխատակից',
        block2Employee1:'Նեռբեռնե՛ք հավելվածը',
        block2Employee2:{a:'Գրանցվե՛ք',b:'Ավելացրե՛ք նպատակը',c:'Կցե՛ք քարտը',d:'Միացե՛ք հաստատությանը'},
        block2Employee3:'Հետևե՛ք Ձեր վաստակած թեյավճարների մուտք ու ելքին',
        block2btn3:'Հաստատություն',
        block2Institution1:'Գրանցվե՛ք հաստատության համար նախատեսված կառավարակետում',
        block2Institution2:'Ավելացրե՛ք աշխատակիցների ID համարները հարթակում',
        block2Institution3:'Հետևե՛ք թեյավճարների, մեկնաբանությունների և աշխատակիցների ակտիվության վիճակագրությանը',
        block3Text1:'Առավելություններ',
        block3Text2:'Անձնական կառավարակետ վիճակագրությանը հետևելու համար',
        block3Text3:'Հարմար բոլոր տեսակի բիզնեսների համար',
        block3Text4:'Բոլոր տպագրական նյութերի մշակում, տպագրում և առաքում',
        block3Text5:{a:'ԱՎՃԱՐ ՄԻԱՑՈՒՄ',b:'Անցե՛ք հղումով ծրագրի հեշտ և արագ ակտիվացման համար։ Կարող եք օգտվել հավելավածից ամբողջովին անվճար։'},
        block3Text6:'Անձնական մենեջեր ինտեգրացիա և դասընթաց անկացնելու համար',
        block3Text7:'Տեխնիկական սպասարկում աշխատանքային ժամերին հեռախոսի / էլ․ փոստի միջոցով',
        block3Text8:'Թեյավճարի նվազագույն և առավելագուն չափ ըստ հաստատության ցանկության',
        block3Text9:'Լեզուների տարբերակները՝ Հայերեն, Ռուսերեն և Անգլերեն',
        block4Text1:'Ինչպե՞ս վճարել',
        block4Text2:'Վճարումներ հայկական և ռուսական բանկային քարտերով',
        block4Text3:'Արագ վճարում Idram բանկային հավելվածով',
        block4Text4:'Արագ վճարում Yoomoney բանկային հավելվածով',
        block4Text5:'Արագ վճարում InecoPay բանկային հավելվածով',
        block5Text1:'միացած հաստատութուն',
        block5Text2:'դրամի չափով թեյավճար միջինում',
        block5Text3:'ունիկալ օգտատեր',
        block5Text4:'մատուցող սստանում է անկանխիկ թեյավճար',
        block5Text5:{a:'ԱՎՃԱՐ ՄԻԱՑՈՒՄ',b:'Անցե՛ք հղումով ծրագրի հեշտ և արագ ակտիվացման համար.',c:'Միանալ հիմա'},
        block6Text1:'Մեր գործընկերները',
        block6Text2:'Հասցե՝',
        block6Text3:'Ժամեր՝',
        block7Text1:'Հավելված անկանխիկ թեյավճար ստանալու համար` Tips.am for Waiters',
        block7Text2:'Միացե՛ք մեր գործըկերներին և թույլ տվե՛ք ձեր հյուրերին թողնել անկանխիկ թեյավաճար QR կոդի միջոցով:',
        block8Text1:'Գործընկերների և հաճախորդների կարծիք',
        block9Text1:'Բաժանորդագրություն',
        block9Text2:'Ստացեք էքսկլյուզիվ տեղեկատվություն և հատուկ առաջարկներ - բաժանորդագրվեք հիմա:',
        block9Text3:'Դուք հաջողությամբ բաժանորդագրվեցիք !',
        block9btn1:'Բաժանորդագրվել',
        block9email:'Էլ․ հասցե',
        block10Text1:'Հաճախ տրվող հարցեր',
        block10Question1:{a:'Ի՞նչպես սկանավորեմ QR կոդը:',b:'Որպեսզի սկանավորեք QR կոդը անհրաժեշտ է մուտք գործել QR scanner հավելվածը ձեր բջջայինում և տեսախցիկի առջև պահել QR-ը: Այն ձեզ կուղղեկցի դեպի վճարման էջ, որտեղ կնշեք թեյավճարի չափը և կլրացնեք ձեր բանկային քարտի թվերը, իսկ ցանկության դեպքում թողել մեկնաբանություն:'},
        block10Question2:{a:'Գոյություն ունի՞ ծառայության միջնորդավճար:',b:'Դուք կարող եք ընտրել երկու տարբերակներից մեկը: Առաջին տարբերակը թույլ է տալիս վճարել միջնորդավճարը մատուցողի փոխարեն, իսկ երկրորդ դեպքում կգանձվի մատուցողի թեյավճարից:'},
        block10Question3:{a:'Փոխանցման միջնորդավճար:',b:'Այն դեպքում երբ համակարգը թույլ է տալիս քարտից քարտ ուղիղ փոխանցումներ կատարել, այստեղ գոյություն ունի բանկային փոքրիկ կոմիսիոն վճար 6,5%-ի չափով, որը կարող եք վճարել ինքներդ կամ թողնել գանձումը մատուցողից:'},
        block10Question4:{a:'Որտե՞ղ է հասանելի tips.am-ը:',b:'Եթե դուք կտեսնենք մեր լոգոն ռեստորանի դռան վրա փակցված կամ սեղաններին ինչ-որ կերպ ցուցադրված, ապա դա նշանն է մեր հասանելիության: Մյուս դեպքերում պարզապես հարցրեք մատուցողին:'},
        block11Text1:'Հետադարձ Կապ',
        block11Text2:'Մենք պատրաստ ենք լսել ձեզ:',
        block11Text3:'Ձեր կարծիքը շատ կարևոր է մեզ համար: Թողեք ձեր կարծիքը և օգնեք մեզ բարելավել մեր ծառայությունը:',
        block11Text4:{a:'Անուն',b:'Ազգանուն',c:'Էլ․ հասցե',d:'Թեմա',e:'Նշում',f:'Ուղարկել',g:'Հաստատության անուն',h:'Հեռախոսահամար',i:'Շնորհակալություն մեզ միանալու համար',j:'Հաստատել'},
        block11SubmitMessage:'Շնորհակալություն, շուտով մենք կապ կհաստատենք ձեզ հետ',
        block12Text1:'Ընդհանուր դրույթներ և պայմաններ',
        block12Text2:'Գաղտնիության քաղաքականություն',
        block12Text3:'Մեր մասին',
        block12Text4:'Առավելություններ',
        block12Text5:'Ի՞նչպես է աշխատում',
        block12Text6:'Հաճախ տրվող հարցեր',
        block12Text7:'Հասցե',
        block12Text8:'Հետադարձ կապ',
        reliablePartner:'Վստահելի գործընկեր'
    },
    ru:{
        advantages:'Преимущества',
        howDoesItWork:'Как это работает',
        oftenGivenQuestions:'Часто задаваемые вопросы',
        block1Text1:'Чаевые в Армении по QR коду без приложения',
        block1Text2:'Оставлять онлайн чаевые в ресторанах и кафе стало удобно как никогда. Просто отсканируйте QR код с помощью телефона и отблагодарите сотрудника за считанные секунды.',
        block1btn1:'Подключиться сейчас',
        block1btn2:'Посмотреть видео',
        block2Text1:'Как это работает',
        block2btn1:'Гость',
        block2Guest1:'Отсканируйте QR код на чеке, визитке или другом носителе. Нет необходимости скачивать приложение или регистрироваться',
        block2Guest2:'Укажите сумму чаевых, оцените работу сотрудника и оставьте отзыв',
        block2Guest3:'Выберите способ оплаты. Принимаются карты армянских и международных банков, в том числе российских Visa, Master, MIR, а также оплата по банковским приложением.',
        block2btn2:'Сотрудник',
        block2Employee1:'Скачайте приложение',
        block2Employee2:{a:'Пройдите регистрацию',b:'Добавьте цель',c:'Прикрепите карте',d:'Подключитесь к админ панели заведения'},
        block2Employee3:'Отслеживайте транзакции по чаевым через приложение',
        block2btn3:'Заведение',
        block2Institution1:'Пройдите регистрацию в панели управления заведением',
        block2Institution2:'Добавьте ID номера сотрудников',
        block2Institution3:'Отслеживайте чаевые, комментарии и активность сотрудников',
        block3Text1:'Преимущества',
        block3Text2:'Личный кабинет для отслеживания статистики',
        block3Text3:'Удобно для всех типов бизнеса',
        block3Text4:'Производство всех печатных материалов',
        block3Text5:{a:'Бесплатное подключение',b:'Перейдтие по ссылке для опертивного подключения к платформе.'},
        block3Text6:'Личный менеджер для проведения интеграции и обучения',
        block3Text7:'Техническое обслуживание по почте и телефону',
        block3Text8:'Корректировки платежного окна по запросу заведения',
        block3Text9:'Доступно на 3 языках: армянский, русский, английский',
        block4Text1:'Как оставить чаевые',
        block4Text2:'Оплата банковской картой армянских и медждународных банков, в том числе и российских банков',
        block4Text3:'Оплата через приложение Idram',
        block4Text4:'Оплата через приложение Yoomoney',
        block4Text5:'Оплата через приложение Inecopay',
        block5Text1:'подключенных заведений',
        block5Text2:'драм средняя сумма чаевых',
        block5Text3:'уникальных пользователей',
        block5Text4:'сотрудников получают безналичные чаевые',
        block5Text5:{a:'Бесплатное подключение',b:'Перейдтие по ссылке для опертивного подключения к платформе.',c:'Подключиться сейчас'},
        block6Text1:'Наши партнеры',
        block6Text2:'Адрес ',
        block6Text3:'Часы работы ',
        block7Text1:'Tips.am for Waiters - приложение для получения чаевых. Только для сотрудников.',
        block7Text2:'',
        block8Text1:'Отзывы наших партнеров',
        block9Text1:'Подписаться',
        block9Text2:'Подпишитесь на эксклюзивные новости и предложения от платформы',
        block9Text3: 'Вы успешно подписались!',
        block9btn1:'Подписаться',
        block9email:'Почта',
        block10Text1:'Часто задаваемые вопросы',
        block10Question1:{a:'Как остканировать QR код?',b:'Для того, чтобы отсканировать QR код, необходимо включить камеру вашего смартфона и навести ее на QR-код. После этого появится ссылка на страницу для оплаты чаевых. На данной странице вы можете выбрать сумму чаевых, оставить комментарий и оценить работу сотрудника.'},
        block10Question2:{a:'Есть ли сумма за обслуживание сервиса?',b:'Вы можете выбрать из двух варинтов. Первый, взять на себя комиссию сервиса и оплатить вместе с чаевыми. Второй - комиссия сервиса будет списана из суммы чаевы.'},
        block10Question3:{a:'Что такое комиссия за перевод?:',b:''},
        block10Question4:{a:'Где можно оплачивать безналичные чаевые через Tips.am?',b:'Если у входа в заведение вы увидите логотип Tips.am или непосредственно в самом заведении буду размещена наши печатные материалы, то это означает, что в данном заведении принимают онлайн чаевые через Tips.am. Также, вы всегда можете спросить об этом у сотрудников'},
        block11Text1:'Обратная связь',
        block11Text2:'Мы готовы выслушать вас.',
        block11Text3:'Ваше мнение важно для нас. Оставьте отзыв о работе нашей платформы и помогите нам стать лучше',
        block11Text4:{a:'Имя',b:'Фамилия',c:'Почта',d:'Тема',e:'Сообщение',f:'Отправить',g:'Имя Завидения',h:'Телефон',i:'Спасибо, что присоединились к нам',j:'Подтверждать'},
        block11SubmitMessage:'Спасибо, мы скоро свяжемся с вами',
        block12Text1:'Общие положения и условия',
        block12Text2:'Политика конфиденциальности',
        block12Text3:'О нас',
        block12Text4:'Преимущества',
        block12Text5:'Как это работает',
        block12Text6:'Часто задаваемые вопросы',
        block12Text7:'Бизнес-адрес',
        block12Text8:'Контакты',
        reliablePartner:'Надежный партнер'

    },
    en:{
        advantages:'Benefits',
        howDoesItWork:'How it works',
        oftenGivenQuestions:'FAQ',
        block1Text1:'Online tipping in Armenia without an app. Just scan the QR code',
        block1Text2:'Online tipping in restaurants and cafes is now more convenient than ever. Simply scan the QR code with your phone and tip the server in seconds',
        block1btn1:'Join now',
        block1btn2:'Watch the video',
        block2Text1:'How it works',
        block2btn1:'Guest',
        block2Guest1:'Scan a QR code on a receipt, business card or other item. No need to download the app or register',
        block2Guest2:'Enter a tip amount, rate the service and leave feedback.',
        block2Guest3:'Choose payment method. Armenian and International bank cards are accepted, including Russian Visa, Master, MIR, as well as payment by bank application.',
        block2btn2:'Employee',
        block2Employee1:'Download the application',
        block2Employee2:{a:'Register',b:'Add a destination',c:'Attach a card',d:'Connect to the establishment\'s admin panel'},
        block2Employee3:'Track tip transactions through the application',
        block2btn3:'Establishment',
        block2Institution1:'Log in to your establishment\'s admin panel',
        block2Institution2:'Add employee ID numbers',
        block2Institution3:'Track tips, comments and employee activity',
        block3Text1:'Benefits',
        block3Text2:'Personal account for tracking statistics',
        block3Text3:'Convenient for all types of businesses',
        block3Text4:'Production of all printed materials',
        block3Text5:{a:'Free connection',b:'Click on the link to connect to the platform'},
        block3Text6:'Personal manager for integration and training',
        block3Text7:'Technical support by email and telephone',
        block3Text8:'Correction of the payment window at the request of the establishment',
        block3Text9:'Available in 3 languages: Armenian, Russian, English.',
        block4Text1:'How to leave a tip',
        block4Text2:'Payment by bank card of Armenian and International banks, including Russian banks.',
        block4Text3:'Payment via Idram app',
        block4Text4:'Payment via Yoomoney application',
        block4Text5:'Payment via Inecopay application',
        block5Text1:'connected establishments',
        block5Text2:'AMD average tip amount',
        block5Text3:'unique users',
        block5Text4:'employees receiving cashless tips',
        block5Text5:{a:'Free connection',b:'Click on the link to join to the platform.',c:'Join now'},
        block6Text1:'Our partners',
        block6Text2:'Address ',
        block6Text3:'Opening hours ',
        block7Text1:'Tips.am for Waiters is an application for tipping. For staff only.',
        block7Text2:'',
        block8Text1:'Reviews from our partners',
        block9Text1:'Subscribe',
        block9Text2:'Subscribe for exclusive news and offers from the platform',
        block9Text3: 'You have successfully subscribed!',
        block9btn1:'Subscribe',
        block9email:'Email',
        block10Text1:'FAQ',
        block10Question1:{a:'How do I scan a QR code?',b:'To scan a QR code, you need to turn on your smartphone\'s camera and point it at the QR code. A link to the tip payment page will then appear. On this page you can choose the amount of the tip, leave a comment and rate the employee\'s work.'},
        block10Question2:{a:'Is there a fee for the service?',b:'You can choose between two options. The first is to take the service commission and pay it together with the tip. The second is to deduct the service fee from the tip amount.'},
        block10Question3:{a:'What is a transfer fee?',b:''},
        block10Question4:{a:'Where can I pay cashless tips via Tips.am?',b:'If you see the Tips.am logo at the entrance of an establishment or our printed material in the establishment itself, it means that the establishment accepts online tips through Tips.am. You can also always ask the staff.'},
        block11Text1:'Feedback',
        block11Text2:'We are ready to listen to you.',
        block11Text3:'Your opinion matters to us. Give us your feedback on our platform and help us get better.',
        block11Text4:{a:'Name',b:'Last Name',c:'Email',d:'Theme',e:'Message',f:'Send',g:'Establishment Name',h:'Phone',i:'Thanks for joining us',j:'Confirm'},
        block11SubmitMessage:'Thank you, we will contact you soon',
        block12Text1:'Terms and Conditions',
        block12Text2:'Privacy Policy',
        block12Text3:'About us',
        block12Text4:'Benefits',
        block12Text5:'How it works',
        block12Text6:'FAQ',
        block12Text7:'Business Address',
        block12Text8:'Contact us',
        reliablePartner:'Reliable partner'
    }
}
