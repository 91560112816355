import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { TextField } from '@mui/material';
import "./components.scss";
import { useState } from 'react';
import axios from 'axios';
import SuccessfullySentPopUp from './successfullySentPopUp';


export default function FeedBackDialog({ text,language }) {
  const [open, setOpen] = useState(false);
  const inputStyle = {
    marginBottom: '20px',
    width: '100%',
  }
  const inputStyle1 = {
    marginTop: '30px',
    marginBottom: '20px',
    width: '100%',
  }

  const handleClickOpen = () => {
    setOpen(true);
    setName("")
    setRestaurantName("")
    setEmail("")
    setPhone("")
  };
  const handleClose = () => {
    setOpen(false);
    setName("")
    setRestaurantName("")
    setEmail("")
    setPhone("")
    setOpenDialog(false)
  };
  const onNameChange = (e) => {
    setName(e.target.value)
    setNameFilled(true)
  }
  const onRestuarantNameChange = (e) => {
    setRestaurantName(e.target.value)
    setRestaurantNameFilled(true)
  }
  const onEmailChange = (e) => {
    setEmail(e.target.value)
    setEmailFilled(true)
  }
  const onPhoneChange = (e) => {
    setPhone(e.target.value)
    setPhoneFilled(true)
  }
  const [name, setName] = useState("")
  const [restaurantName, setRestaurantName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [openDialog,setOpenDialog] = useState(false)
  const [nameFilled, setNameFilled] = useState(true)
  const [restaurantNameFilled, setRestaurantNameFilled] = useState(true)
  const [emailFilled, setEmailFilled] = useState(true)
  const [phoneFilled, setPhoneFilled] = useState(true)
  let emailReg = /^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/

  const sendData = async (e) => {
    e.preventDefault()
    if (name.trim().length>=2 && restaurantName.trim().length>=2 && emailReg.test(email) && phone.trim().length>6) {
      try {
        const response = await axios.post('https://api.tips.am/api/users/contact-us', { name, restaurantName, email, phone })
        console.log(response.data)
        setName("")
        setRestaurantName("")
        setEmail("")
        setPhone("")
        setOpenDialog(true)
      } catch (error) {
        console.log(error);
      }
    }
    if (!name.length) {
      setNameFilled(false)
    }
    if (!restaurantName.length) {
      setRestaurantNameFilled(false)
    }
    if (!email.length && !emailReg.test(email)) {
      setEmailFilled(false)
    }
    if (!phone.length) {
      setPhoneFilled(false)
    }
  }

  return (
    <div>
      <button style={{
        width: "220px",
        height: " 48px",
        background: " linear-gradient(90deg, #03D187 -2.6%, #1AC5BA -2.59%, #12D51A 100%)",
        borderRadius: "8px",
        border: "none",
        color: "white",
        fontSize: "18px",
      }} variant="outlined" onClick={handleClickOpen}>
        {text}
      </button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className='dialog' style={{
          display: 'flex',
          flexDirection: 'column',
          width: '460px'
        }}>
          <i style={{
            fontSize: '20px',
            fontWeight: '100',
            color: '#B2B2B2',
            position: 'absolute',
            right: '25px',
            cursor: 'pointer'
          }} onClick={handleClose} className="fa fa-times" aria-hidden="true"></i>
          <h1 style={{
            fontSize: '24px',
            textAlign: 'center',
            marginTop: '20px',
            fontWeight: '400'
          }}>
          {
            text
          }
          </h1>
          <form onSubmit={sendData}>
            <TextField
              style={inputStyle1}
              id="outlined-basic"
              label={language?.block11Text4?.a}
              name='user_name'
              value={name}
              onChange={onNameChange}
              error={!nameFilled}
              variant="outlined"
            />
            <TextField
              style={inputStyle}
              id="outlined-basic"
              label={language?.block11Text4?.g}
              name='institution_name'
              value={restaurantName}
              onChange={onRestuarantNameChange}
              error={!restaurantNameFilled}
              variant="outlined"
            />
            <TextField
              style={inputStyle}
              id="outlined-basic"
              label={language?.block11Text4?.c}
              name='user_email'
              value={email}
              onChange={onEmailChange}
              error={!emailFilled}
              variant="outlined"
            />
            <TextField
              style={inputStyle}
              id="outlined-basic"
              label={language?.block11Text4.h}
              name='user_phone'
              value={phone}
              onChange={onPhoneChange}
              error={!phoneFilled}
              variant="outlined"
            />
            <DialogActions>
              <button className='feedBackDialogButton'
                style={
                     name.trim().length>=2
                  && restaurantName.trim().length>=2
                  && emailReg.test(email)
                  && phone.trim().length>6 ? {
                  width: '105%',
                  height: '48px',
                  background: "linear-gradient(90deg, rgb(0 190 121) 0%, rgb(0 255 10) 100%)",
                  borderRadius: '8px',
                  border: "none",
                  color: 'white',
                  fontSize: '18px',
                  fontWeight: '100',
                  margin: '0 auto'
                } : {
                  width: '105%',
                  height: '48px',
                  background: "linear-gradient(90deg, rgba(3, 209, 134, 0.314) 0%, rgba(18, 213, 25, 0.368) 100%)",
                  borderRadius: '8px',
                  border: "none",
                  color: 'white',
                  fontSize: '18px',
                  fontWeight: '100',
                  margin: '0 auto'
                }} autoFocus>
                {language?.block11Text4?.f}
              </button>
            </DialogActions>
          </form>
          {
            openDialog&&<SuccessfullySentPopUp opens={openDialog} text={language.block11Text4.i} button={true} btnText={language.block11Text4.j}/>
          }
          
        </DialogContent>
      </Dialog>
    </div>
  );

}
