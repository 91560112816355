import React from "react"
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
import "./components.scss"
import RatingStars from 'react-rating-stars-component';

SwiperCore.use([Navigation, Pagination]);

const swiperParams = {
  spaceBetween: 20,
  slidesPerView: 3,
  pagination: {
    clickable: true,
  },
  breakpoints: {
    1200: {
      slidesPerView: 3
    },
    810: {
      slidesPerView: 2
    },
    700: {
      slidesPerView: 1.5
    },
    650: {
      slidesPerView: 1.5
    },
    600: {
      slidesPerView: 1.5
    },
    300: {
      slidesPerView: 1
    }
  }
};
const PartnersReview = ({language}) => {
  const partnersReview = [
    { id: 1, FSname: "Արման Հակոբյան", Pstatus: language.reliablePartner, review: "Tips.am  թեյավճարների հարթակը հիանալի գործիք է մեր ռեստորանում անկանխիկ թեյավճար թողնելու գործընթացը պարզեցնելու համար: Այն հեշտացնում է թեյավճարների փոխանցումը, մեծացնում է անձնակազմի մոտիվացիան և հյուրերին տալիս է կարծիքներ թողնելու հնարավորություն:" },
    { id: 2, FSname: "Դավիթ Սարգսյան", Pstatus: language.reliablePartner, review: "Օնլայն թեյավճարների հարթակը հիանալի լուծում է, այն հեշտացնում է թեյավճարների ստացման և փոխանցման գործընթացը, արագ է և պարզ: Խորհուրդ եմ տալիս այն որպես նորարարական և հարմար լուծում ռեստորանների համար։" },
    { id: 3, FSname: "Հրաչյա Մարգարյան", Pstatus:  language.reliablePartner, review: "Tips.am-ի շնորհիվ մեր աշխատակիցները կարող են հեշտությամբ ստանալ անկանպխիկ թեյավճարները իրենց բանկային քարտի վրա, ինչը պարզեցնում հաշվապահական հաշվառումը: Բացի այդ, հարթակը հնարավորություն է տալիս հյուրերին կարծիքներ թողնել, ինչը մեզ օգնում է վերահսկել ծառայության որակը։" },
    { id: 4, FSname: "Արտակ Մելքոնյան", Pstatus:  language.reliablePartner, review: "Tips.am-ը թույլ է տալիս հետևել թեյավճարների ստատիստիկային, ինչպես նաև հնարավորություն է տալիս հյուրերին թողնել կարծիքներ և մեկնաբանություններ: Այս նորարար լուծումն օգնում է մեզ պահպանել թեյավճարների թափանցիկությունն ու արդարությունը, և մեր անձնակազմը գոհ է այս համակարգից:" },
  ]
  const firstIdStyle = {
    width: "320px",
    minHeight: "100px",
    padding: "24px",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "space-around",
    alignItems: "center",
    boxShadow: ' 0px 0px 6px rgba(0, 0, 0, 0.08)',
    background: " linear-gradient(90deg, rgba(133, 102, 255, 0.08) 0%, rgba(217, 217, 217, 0.02) 100%)"
  }
  const lastIdStyle = {
    width: "320px",
    minHeight: "100px",
    padding: "24px",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "space-around",
    alignItems: "center",
    boxShadow: ' 0px 0px 6px rgba(0, 0, 0, 0.08)',
    background: " linear-gradient(270deg, rgba(133, 102, 255, 0.08) 0%, rgba(217, 217, 217, 0.02) 100%)"
  }
  const reviewStyle = {
    width: "320px",
    minHeight: "100px",
    padding: "24px",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "space-around",
    alignItems: "center",
    boxShadow: ' 0px 0px 6px rgba(0, 0, 0, 0.08)',
  }

  return (
    <div className="partRev">
      <h1 className="revH1" style={{
        fontSize: "40px",
        textAlign: "center"
      }}>
        {language.block8Text1}
      </h1>
      <div className="PartnersReview">
        <Swiper style={{ height: 'auto', paddingTop: "20px", paddingLeft: '5px', cursor: 'pointer', paddingBottom:'40px' }} {...swiperParams}>
          {
            partnersReview?.map(({ id, FSname, Pstatus, stars, review }) => {
              return <SwiperSlide key={id}
                style={id === 1 ?
                  firstIdStyle :
                  id === partnersReview.length ?
                    lastIdStyle :
                    reviewStyle
                }>
                <h1>
                  {FSname}
                </h1>
                <p className="status">
                  <i style={{ marginRight: '7px', fontSize: "15px" }} className="fa fa-check-circle" aria-hidden="true"></i>
                  {Pstatus}
                </p>
                <p className="review">
                  {review}
                </p>
              </SwiperSlide>
            })
          }
          <div slot="pagination" className="swiper-pagination"></div>
        </Swiper>
      </div>
    </div>
  )
};

export default PartnersReview;
