import React from "react"
import "./components.scss"
import { useState } from "react";
import axios from "axios";
import SuccessfullySentPopUp from "./successfullySentPopUp";

const SubscriptionEmail = ({language}) => {
  const [email, setEmail] = useState("")
  const [openDialog,setOpenDialog] = useState(false)
  let emailReg = /^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/

  const onEmailChange = e => setEmail(e.target.value)
  
  const sendSubscriptionData = async (e) => {
    e.preventDefault()
    if (emailReg.test(email)) {
      try {
        const response = await axios.post('https://api.tips.am/api/users/email/subscribe', { email })
        console.log(response.data)
        setOpenDialog(true)
      } catch (error) {
        console.log(error);
      }

      setEmail('')
    }
  }
  setTimeout(() => {
    setOpenDialog(false)
  },3000);
  return (
    <div className="SubscriptionEmail">
      <div className="subscriptionBox">
        <div className="sub">
          <h1 style={{
            fontSize: '24px',
            lineHeight: "28px"
          }}>
            {language.block9Text1}
          </h1>
          <p style={{
            lineHeight: "24px"
          }}>
            {language.block9Text2}
          </p>
        </div>
        <div className="email">
          <form onSubmit={sendSubscriptionData}>
            <input
              type="email"
              name='user_email'
              required
              value={email}
              onChange={onEmailChange}
              placeholder={language.block9email}
            />
            <button
              className={emailReg.test(email) ? "buttonActive" : undefined}
              disabled={emailReg.test(email) ? false : true}
            >
              {language.block9btn1}

            </button>
          </form>
          {
            openDialog&&<SuccessfullySentPopUp opens={openDialog} text={language.block11SubmitMessage} button={false} btnText={language.block11Text4.j} />
          }
        </div>
      </div>

    </div>
  )
};

export default SubscriptionEmail;
