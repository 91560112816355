import { Route, Routes } from 'react-router-dom';
import './App.css';
import { routes } from './utils/urls';


function App() {
  document.addEventListener('contextmenu', event => event.preventDefault());
  document.addEventListener('keydown', event => {
    if (event.ctrlKey && (event.key === 'c' || event.key === 'C')) {
      event.preventDefault();
    }
  });
  return (
    <div className="App">
      <Routes>
        {
          routes.map(({ path, element }) => {
            return <Route key={path} path={path} element={element} exact="true" />
          })
        }
      </Routes>
    </div>
  );
}

export default App;
