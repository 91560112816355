import React from "react";
import "./components.scss";
import { TextField } from "@mui/material";
import { useState } from "react";
import axios from "axios";
import SuccessfullySentPopUp from "./successfullySentPopUp";
const FeedBack = ({language}) => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [theme, setTheme] = useState("");
  const [message, setMessage] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [nameFilled, setNameFilled] = useState(true);
  const [surnameFilled, setSurnameFilled] = useState(true);
  const [emailFilled, setEmailFilled] = useState(true);
  const [themeFilled, setThemeFilled] = useState(true);
  const [messageFilled, setMessageFilled] = useState(true);
  const emailRegex = /^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  const sendDataFeedBack = async (e) => {
    e.preventDefault();
    if (
      name.trim().length >= 2 &&
      surname.trim().length >= 2 &&
      emailRegex.test(email) &&
      theme.trim().length >= 2 &&
      message.trim().length >= 2
    ) {
      try {
        const response = await axios.post(
          "https://api.tips.am/api/users/email/support/v1",
          { name, surname, email, theme, message }
        );
        console.log(response.data);
        setName("");
        setSurname("");
        setEmail("");
        setTheme("");
        setMessage("");
        setOpenDialog(true);
      } catch (error) {
        console.log(error);
      }
    }

    if (!name.length) {
      setNameFilled(false);
    }
    if (!surname.length) {
      setSurnameFilled(false);
    }
    if (!email.length) {
      setEmailFilled(false);
    }
    if (!theme.length) {
      setThemeFilled(false);
    }
    if (!message.trim()) {
      setMessageFilled(false);
    }
  };
  const onNameChange = (e) => {
    setName(e.target.value);
    setNameFilled(true);
  };
  const onSurnameChange = (e) => {
    setSurname(e.target.value);
    setSurnameFilled(true);
  };
  const onEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailFilled(true);
  };
  const onThemeChange = (e) => {
    setTheme(e.target.value);
    setThemeFilled(true);
  };
  const onMessageChange = (e) => {
    setMessage(e.target.value);
    setMessageFilled(true);
  };

  return (
    <div className="FeedBack">
      <h1>{language.block11Text1}</h1>
      <div className="feedBox">
        <div className="text">
          <p>{language.block11Text2}</p>
          <p>
            {language.block11Text3}
          </p>
        </div>
        <form className="input" onSubmit={sendDataFeedBack}>
          <div>
            <TextField
              id="outlined-basic"
              label={language.block11Text4.a}
              name="user_name"
              value={name}
              onChange={onNameChange}
              variant="outlined"
              style={{ marginRight: "15px" }}
              error={!nameFilled}
            />
            <TextField
              id="outlined-basic"
              label={language.block11Text4.b}
              surname="user_surname"
              value={surname}
              onChange={onSurnameChange}
              variant="outlined"
              error={!surnameFilled}
            />
          </div>
          <TextField
            id="outlined-basic"
            label={language.block11Text4.c}
            name="user_email"
            value={email}
            onChange={onEmailChange}
            variant="outlined"
            error={!emailFilled}
          />
          <TextField
            id="outlined-basic"
            label={language.block11Text4.d}
            name="user_theme"
            value={theme}
            onChange={onThemeChange}
            variant="outlined"
            error={!themeFilled}
          />
          <textarea
            rows="4"
            placeholder={language.block11Text4.e}
            value={message}
            onChange={onMessageChange}
            style={!messageFilled ? { border: ".5px solid red" } : undefined}
          ></textarea>
          <button
            className={
              name.trim().length >= 2 &&
              surname.trim().length >= 2 &&
              emailRegex.test(email) &&
              theme.trim().length >= 2 &&
              message.trim().length >= 2
                ? "buttonActive1"
                : undefined
            }
          >
            {language.block11Text4.f}
          </button>
          {openDialog && <SuccessfullySentPopUp opens={openDialog} text={language.block11SubmitMessage} button={true} btnText={language?.block11Text4.j}/>}
        </form>
      </div>
    </div>
  );
};

export default FeedBack;
