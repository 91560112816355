import React, { useEffect, useState } from "react";
import "./cardConnectStatus.scss";
import tipsAM from "../imgs/forgotTipsAm.svg";
import checked from "../imgs/checked.svg";
import error from "../imgs/error.svg";
import axios from "axios";

const CardConnectStatus = () => {
  const query = new URLSearchParams(window.location.search);
  const orderId = query.get("orderId");
  const [requestStatus, setRequestStatus] = useState();
  const statusChecker = async (e) => {
    try {
      const response = await axios.put(
        `https://api.tips.am/api/cards/bind/check/${orderId}`
      );
      setRequestStatus(response.status);
    } catch (error) {}
  };
  useEffect(() => {
    statusChecker();
  }, []);

  return (
    <div className="cardConnectStatusPage">
      <div className="cardConnectStatusHeader">
        <img src={tipsAM} alt="tips.am" />
      </div>
      <div className="cardConnectStatus">
        <img src={requestStatus === 200 ? checked : error} alt="status" />
        <p
          style={{
            color: requestStatus === 200 ? "green" : "red",
          }}
        >
          {requestStatus === 200
            ? "Your card was successfully connected"
            : "Something went wrong"}
        </p>
      </div>
    </div>
  );
};

export default CardConnectStatus;
