import React from "react";
import "./passwordForgot.scss";
import tipsAm from "../imgs/forgotTipsAm.svg";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { useState } from "react";
import { useParams } from "react-router-dom";
import checked from "../imgs/checked.svg";
import error from "../imgs/error.svg";

const PasswordForgotPage = () => {
  const { token } = useParams();
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [isPasswordWritten, setIsPasswordWritten] = useState(true);
  const [isRepeatPasswordWritten, setIsRepeatPasswordWritten] = useState(true);
  const [status, setStatus] = useState();
  const [open, setOpen] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(true);
  const passwordReg = /[a-zA-Z0-9]{3,30}/;
  const changePassword = async (e) => {
    e.preventDefault();
    if (
      password.trim().length &&
      passwordReg.test(password) &&
      repeatPassword.trim().length &&
      passwordReg.test(repeatPassword) &&
      password === repeatPassword
    )
      try {
        const response = await axios.post(
          `https://api.tips.am/api/users/forgot-password/confirm?token=${token}`,
          { password, repeatPassword }
        );
        console.log(response.data);
        setStatus(response.status);
        setOpen(true);
        setPassword('')
        setRepeatPassword('')
      } catch (error) {
        setStatus(error.response.status);
        setOpen(true);
      }
    password.trim() && password===repeatPassword? setIsPasswordWritten(true) : setIsPasswordWritten(false);
    repeatPassword.trim() && password===repeatPassword
      ? setIsRepeatPasswordWritten(true)
      : setIsRepeatPasswordWritten(false);

    passwordReg.test(password) &&
    passwordReg.test(repeatPassword) &&
    password != repeatPassword
      ? setPasswordMatch(false)
      : setPasswordMatch(true);
  };

  return (
    <div className="forgotPasswordPage">
      <div className="forgotHeader">
        <img src={tipsAm} alt="tips.am" />
      </div>
      <form onSubmit={changePassword} className="forgotForm">
        <p>Create new password</p>
        {!passwordMatch && (
          <div style={{ color: "red",position:'absolute',top:'30px' }}>
            Please make sure your passwords match
          </div>
        )}
        <TextField
          fullWidth
          required
          type="password"
          label="Password"
          id="fullWidth"
          error={!isPasswordWritten}
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
        <TextField
          fullWidth
          required
          type="password"
          label="Repeat password"
          id="fullWidth"
          error={!isRepeatPasswordWritten}
          value={repeatPassword}
          onChange={(e) => {
            setRepeatPassword(e.target.value);
          }}
        />

        <button
          style={
            password.trim().length &&
            passwordReg.test(password) &&
            repeatPassword.trim().length &&
            passwordReg.test(repeatPassword) &&
            password === repeatPassword
              ? {
                  background: "#478F4F",
                }
              : undefined
          }
        >
          Save
        </button>
      </form>
      {open && (
        <div>
          {status === 200 ? (
            <div className="successfullyChanged">
              <img src={checked} alt="checked" />
              <p>
                You changed your password successfully
              </p>
            </div>
          ) : (
            <div className="errorMessage">
              <img src={error} alt="error" />
              <p>Something went wrong</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PasswordForgotPage;
