import React from "react";
import "./confirmPage.scss";
import tipsAm from "../imgs/forgotTipsAm.svg";
import checked from "../imgs/checked.svg";
import error from "../imgs/error.svg";
import { useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useState } from "react";

const ConfirmPage = () => {
  const { token } = useParams();
  const [requestStatus, setRequestStatus] = useState();
  const statusChecker = async (e) => {
    try {
      const response = await axios.put(
        `https://api.tips.am/api/users/email/confirm?token=${token}`
      );
      console.log("response");
      setRequestStatus(response.status);
    } catch (error) {}
  };
  useEffect(() => {
    statusChecker();
  }, []);
  return (
    <div className="confirmPage">
      <div className="confirmHeader">
        <img src={tipsAm} alt="tips.am" />
      </div>
      <div className="confirmStatus">
        <img src={requestStatus === 200 ? checked : error} alt="status" />
        <p
          style={{
            color: requestStatus === 200 ? "green" : "red",
          }}
        >
          {requestStatus === 200
            ? "You have successfully confirmed your email"
            : "Something went wrong"}
        </p>
      </div>
    </div>
  );
};

export default ConfirmPage;
