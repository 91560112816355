import React from "react"

const Institution = ({language}) => {
  return (
    <div className="Institution">
      <div>
        <img src={require("../components/imgs/institution1.png")} alt="institutionImg" />
        <p>
            {language.block2Institution1}
        </p>
        <img className="arrow1 ar1" style={{position:'absolute', top:"145px",left:'290px'}} src={require("../components/imgs/Arrow.png")} alt="arrowImg" />

      </div>

      
      <div>
        <img src={require("../components/imgs/institution2.png")} alt="institutionImg" />
        <p style={{margin:'64px'}}>
            {language.block2Institution2}
        </p>
      </div>
      
      
      <div>
        <img src={require("../components/imgs/institution3.png")} alt="institutionImg" />
        <p>
            {language.block2Institution3} <br/>
            {/*{language.block2Institution3.b}*/}
        </p>
        <img className="arrow1 ar2" style={{position:'absolute', top:"145px",right:'290px'}} src={require("../components/imgs/Arrow.png")} alt="arrowImg" />

      </div>
    </div>
  )
};

export default Institution;
