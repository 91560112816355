import React, { useState } from "react";
import "./components.scss";

const OftenGivenQuestions = ({language}) => {
  let style = {
    width: "100%",
    height: "60px",
    borderRadius: "8px",
    background: "#FFFFFF",
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.08)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    padding: "16px",
    marginBottom: "15px",
  };
  let styleOpen = {
    width: "100%",
    minHeight: "125px",
    borderRadius: "8px",
    background: "#FFFFFF",
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.08)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "16px",
    marginBottom: "15px",
  };
  const [inp1, setInp1] = useState(false);
  const [inp2, setInp2] = useState(false);
  const [inp3, setInp3] = useState(false);
  const [inp4, setInp4] = useState(false);
  const setAllFalse = () => {
    setInp1(false);
    setInp2(false);
    setInp3(false);
    setInp4(false);
  };
  return (
    <div id="OftenGivenQuestions" className="OftenGivenQuestions">
      <h1 className="oftH1" style={{textAlign:'center'}}>{language.block10Text1}</h1>
      <div className="questionBox">
        <div
          onClick={() => {
            setAllFalse();
            setInp1(!inp1);
          }}
          style={inp1 ? styleOpen : style}
        >
          <div>
            <h1>{language.block10Question1.a}</h1>
            {inp1 ? (
              <img
                draggable="false"
                src={require("./imgs/moreOpened.png")}
                alt="Image"
              />
            ) : (
              <img
                draggable="false"
                src={require("./imgs/moreClosed.png")}
                alt="Image"
              />
            )}
          </div>
          {inp1 ? (
            <p>
              {language.block10Question1.b}
            </p>
          ) : undefined}
        </div>
        <div onClick={() => {
            setAllFalse();
            setInp2(!inp2);
          }} style={inp2 ? styleOpen : style}>
          <div>
            <h1>{language.block10Question2.a}</h1>
            {inp2 ? (
              <img
                draggable="false"
                src={require("./imgs/moreOpened.png")}
                alt="Image"
              />
            ) : (
              <img
                draggable="false"
                src={require("./imgs/moreClosed.png")}
                alt="Image"
              />
            )}
          </div>
          {inp2 ? (
            <p>
              {language.block10Question2.b}
            </p>
          ) : undefined}
        </div>
        <div onClick={() => {
            setAllFalse();
            setInp3(!inp3);
          }} style={inp3 ? styleOpen : style}>
          <div>
            <h1>{language.block10Question3.a}</h1>
            {inp3 ? (
              <img
                draggable="false"
                src={require("./imgs/moreOpened.png")}
                alt="Image"
              />
            ) : (
              <img
                draggable="false"
                src={require("./imgs/moreClosed.png")}
                alt="Image"
              />
            )}
          </div>
          {inp3 ? (
            <p>
              {language.block10Question3.b}
            </p>
          ) : undefined}
        </div>
        <div onClick={() => {
            setAllFalse();
            setInp4(!inp4);
          }} style={inp4 ? styleOpen : style}>
          <div>
            <h1>{language.block10Question4.a}</h1>
            {inp4 ? (
              <img
                draggable="false"
                src={require("./imgs/moreOpened.png")}
                alt="Image"
              />
            ) : (
              <img
                draggable="false"
                src={require("./imgs/moreClosed.png")}
                alt="Image"
              />
            )}
          </div>
          {inp4 ? (
            <p>
              {language.block10Question4.b}
            </p>
          ) : undefined}
        </div>
      </div>
    </div>
  );
};

export default OftenGivenQuestions;
