import React from "react"

const Employee = ({language}) => {
  return (
    <div className="Employee">
      <div className="EmpDiv">
        <img src={require("../components/imgs/employeeTel1.png")} alt="phoneImg" />
        <p>
          {language.block2Employee1}
        </p>
        <div className="div">
          <a href="https://apps.apple.com/in/app/tips-am/id1623231593" target="_blank">
            <img src={require("../components/imgs/appStore.png")} alt="appStore" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=am.tip.app&hl=ru&gl=US" target="_blank">
            <img src={require("../components/imgs/playMarket.png")} alt="playMarket" />
          </a>

        </div>
      </div>

      <div className="EmpDiv">
        <img src={require("../components/imgs/employeeTel2.png")} alt="employeePhone" />
        <ul style={{ textAlign: 'left', marginTop: '43px', lineHeight: '24px' }}>
          <li>
            {language.block2Employee2.a}
          </li>
          <li>
            {language.block2Employee2.b}
          </li>
          <li>
            {language.block2Employee2.c}
          </li>
          <li>
            {language.block2Employee2.d}
          </li>
        </ul>
      </div>

      <div className="EmpDiv">
        <img src={require("../components/imgs/employeeTel3.png")} alt="employeePhone" />
        <p>
          {language.block2Employee3}
        </p>
      </div>
    </div>
  )
};

export default Employee;
