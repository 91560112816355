import React from "react";
import "./privacy.scss";
import tipsAM from "../imgs/forgotTipsAm.svg";
const Privacy = () => {
  return (
    <div className="Privacy">
      <div className="privacyHeader">
        <img src={tipsAM} alt="tips.am" />
      </div>
      <div className="privacyTextBox">
        <p>Գաղտնիության քաղաքականություն</p>
        <p>Գաղտնիության քաղաքականություն Ստեպպերս Օրիոն ընկերության համար</p>
        <p
          style={{
            margin: "20px 0",
          }}
        >
          Թարմացվել է` 20.04.2022
        </p>
        <p
          style={{
            marginBottom: "20px",
          }}
        >
          Մեծատառով գրված բառերը, որոնք չեն սահմանվում սույն Գաղտնիության
          քաղաքականությամբ՝ սահմանված են Ընկերության կայքում տեղադրված Ընդհանուր
          Դրույթներով և Պայմաններով:
        </p>
        <p>
          Բարի գալուստ www.tips.am (Կայք), որը պատկանում և շահագործվում է
          Ստեպպերս Օրիոն ՍՊ ընկերության կողմից (Ընկերություն, մենք, մեզ, մեր,
          Օպերատոր): Ստեպպերս Օրիոն ընկերությունը տրամադրում է թեյավճարների
          անկանխիկ վճարման ծրագրային ապահովում (Ծառայություն) անհատներին կամ
          հաճախորդներին (Թեյավճար վճարող), ինչպես նաև Թեյավճար վճարողներին
          ծառայություն մատուցողներին (Թեյավճար ստացող) և ծառայություն մատուցող
          հաստատության (ռեստորան, սրճարան, գեղեցկության սրահ և այլն) օգտատերերին
          (Ադմինիստրատոր), միասին՝ Օգտատեր, դուք, ձեր: Որպեսզի մենք կարողանանք
          մատուցել Ծառայություն Ընկերությունը հավաքում և մշակում է մեր Կայքի
          այցելուների և Օգտատերերի անձնական տվյալները:
        </p>
        <p>
        Սույն Գաղտնիության
          քաղաքականությունը վերաբերում է Ընկերության կողմից հավաքագրված անձնական
          տեղեկատվությանը, երբ դուք օգտագործում եք մեր Կայքը և/կամ
          Ծառայությունները, կամ այլ կերպ տրամադրում եք մեզ անձնական
          տեղեկություններ: Խնդրում ենք ուշադիր կարդալ այն, որպեսզի հասկանաք ձեր
          անձնական տեղեկատվության վերաբերյալ մեր քաղաքականությունն ու
          գործելակերպը, և թե ինչպես ենք մենք վերաբերվելու դրանց: Եթե համաձայն
          չեք մեր Գաղտնիության քաղաքականության հետ, խնդրում ենք մի ներբեռնեք,
          տեղադրեք, գրանցվեք, մուտք գործեք կամ օգտագործեք Կայքը կամ
          Ծառայությունները: Այնուամենայնիվ, խնդրում ենք կարդալ սույն
          Գաղտնիության քաղաքականությունը նախքան մեր Ծառայություններից օգտվելը,
          որը ձեզ մանրամասն տեղեկություն կտա անձնական տվյալների մշակման մեր
          իրավական հիմքերի և ձեր անձնական տվյալների մշակման հետ կապված ձեր
          իրավունքների մասին: Սույն Գաղտնիության քաղաքականությունը (այսուհետ՝
          Քաղաքականություն) վերաբերում է բոլոր այն տեղեկատվությանը, որն
          Օպերատորը կարող է ստանալ Օգտատիրոջ մասին վերջինիս կողմից
          Ծառայությունից օգտվելու ընթացքում՝ համաձայն կողմերի միջև կնքված
          Համաձայնագրի։
        </p>
        <p>ԵՐԵԽԱՆԵՐԻ ԳԱՂՏՆԻՈՒԹՅՈՒՆԸ</p>
        <p>
          Ընկերությունը միտումնավոր տեղեկություններ չի հավաքում 16 տարեկանից
          ցածր երեխաներից: Եթե դուք 16 տարեկանից ցածր եք, խնդրում ենք մեզ որևէ
          անձնական տվյալ չներկայացնել: Մենք խրախուսում ենք ծնողներին և
          խնամակալներին վերահսկել իրենց երեխաների ինտերնետի օգտագործումը և օգնել
          կիրառելու մեր Գաղտնիության քաղաքականությունը՝ հրահանգելով իրենց
          երեխաներին երբեք չտրամադրել անձնական տվյալներ առանց ծնողների և
          խնամակալների թույլտվության: Եթե հիմքեր ունեք ենթադրելու, որ 16
          տարեկանից փոքր երեխան անձնական տվյալներ է տրամադրել Օպերատորին Կայքի
          կամ Ծառայությունների միջոցով, խնդրում ենք կապ հաստատել մեզ հետ՝
          support@tips.am էլ. փոստի միջոցով, և մենք անմիջապես կուղեփակենք և
          կոչնչացնենք այդ տեղեկատվությունը մեր տվյալների բազայից:
        </p>
        <p>ԳԱՂՏՆԻՈՒԹՅԱՆ ՔԱՂԱՔԱԿԱՆՈՒԹՅԱՆ ՓՈՓՈԽՈՒԹՅՈՒՆՆԵՐԸ</p>
        <p
          style={{
            marginBottom: "20px",
          }}
        >
          Գաղտնիության քաղաքականությունը վերջին անգամ թարմացվել է վերը նշված
          ամսաթվին, սակայն մենք առաջարկում ենք ժամանակ առ ժամանակ վերանայել այն,
          քանի որ մեր Կայքը և/կամ Ծառայությունները և մեր գործունեությունը կարող
          է փոխվել: Արդյունքում, երբեմն կարող է անհրաժեշտություն լինել, որ մենք
          փոփոխություններ կատարենք Գաղտնիության քաղաքականության մեջ:
          Ընկերությունն իրեն իրավունք է վերապահում թարմացնել կամ փոփոխել սույն
          Գաղտնիության քաղաքականությունը ցանկացած ժամանակ՝ առանց նախնական
          ծանուցման: Այնուամենայնիվ, եթե մենք փոփոխություններ կատարենք, որոնք,
          մեր կարծիքով, էականորեն կազդեն այս Գաղտնիության քաղաքականության կամ
          Ձեր իրավունքների վրա, մենք անմիջապես կտեղեկացնենք Ձեզ այդ
          փոփոխությունների մասին: Ձեր կողմից Կայքի և/կամ Ծառայությունների
          շարունակական օգտագործումը սույն Գաղտնիության քաղաքականության ցանկացած
          փոփոխությունից կամ վերանայումից հետո կնշանակի Ձեր համաձայնությունը
          նման փոփոխված Գաղտնիության քաղաքականության պայմանների հետ:
        </p>
        <p>
          Ծառայությունից օգտվելը նշանակում է Օգտատիրոջ անվերապահ համաձայնություն
          Քաղաքականությանը և դրանում նշված նրա անձնական տեղեկատվության մշակման
          պայմաններին: Ընդունելով Օֆերտան՝ Օգտատերը տալիս է իր համաձայնությունը
          Օպերատորին՝ մշակելու իր անձնական տվյալները, այդ թվում՝ կատարելու
          անձնական տվյալների հավաքագրում, համակարգում, պահպանում, փոփոխություն,
          օգտագործում, փոխանցում, ապանձնավորում, արգելափակում, ոչնչացում: Նշված
          պայմանների հետ անհամաձայնության դեպքում Օգտատերը պետք է ձեռնպահ մնա
          Ծառայությունից օգտվելուց: Քաղաքականության համար «Օգտատեր»
          հասկացությունն ունի Ծառայությունից օգտվող Օգտատեր (Թեյավճար վճարող և
          Թեյավճար ստացող) նշանակությունը:
        </p>
        <p
          style={{
            marginBottom: "20px",
          }}
        >
          Օգտատերը նաև իր համաձայնությունն է տալիս Օպերատորի կողմից Համաձայնագրի
          նպատակների համար նախատեսված գործողություններն իրականացնելու համար իր
          անձնական տվյալների փոխանցմանը երրորդ անձանց, եթե առկա է Օպերատորի և
          երրորդ անձանց միջև պատշաճ կնքված Պայմանագիր կամ Համաձայնագիր:
          Համաձայնագրի նպատակների համար գաղտնի չի համարվում այն
          տեղեկատվությունը, որը կարող է ստացվել այլ եղանակով՝ առանց գաղտնիության
          պահանջների խախտման: Օգտատերը կրում է իր կողմից Օպերատորին տրամադրված
          տեղեկատվության ոչ ճշգրիտ լինելու բացասական հետևանքների ռիսկերը:
        </p>
        <p>1. Օպերատորի կողմից մշակվող Օգտատերերի անձնական տվյալները</p>
        <p>
          1.1. Քաղաքականության շրջանակներում «Օգտատիրոջ անձնական տվյալներ»
          նշանակում է.
        </p>
        <p>
          1.1.1. Անձնական այն տվյալները, որոնք Օգտատերը տրամադրում է իր մասին
          Համակարգում գրանցվելիս (հաշիվ ստեղծելիս) կամ Ծառայությունից օգտվելիս
          (անուն, ազգանուն, հեռախոսահամար, էլ. փոստ, պաշտոն, Ադմինիստրատորի
          անվանում):
        </p>
        <p>
          1.1.2. Այն տվյալները, որոնք ավտոմատ կերպով փոխանցվում են
          Ծառայությունից օգտվելու ընթացքում՝ օգտագործելով Օգտատիրոջ սարքում
          տեղադրված և նրա կողմից օգտագործվող ծրագրային ապահովման վերաբերյալ
          տեղեկությունները, այդ թվում՝ IP հասցեն, Օգտատիրոջ բրաուզերի (կամ
          Ծառայություն մուտք գործող այլ ծրագրի) մասին տեղեկությունները,
          սարքավորումների և ծրագրային ապահովման տեխնիկական բնութագրերը, այլ
          տեղեկություններ: Սույն կետում նշված տվյալները և Քաղաքականության 5-րդ
          կետում նշված տվյալները իրենց բնույթով անանուն են և չեն կարող կապված
          լինել որևէ Օգտատիրոջ հետ։
        </p>
        <p>
          1.1.3. Օգտատիրոջ մասին այլ տեղեկություններ, որոնց մշակումը նախատեսված
          է Օպերատորի Ծառայությունից օգտվելու Պայմաններով:
        </p>
        <p>
          1.2. Քաղաքականությունը վերաբերում է միայն Ծառայությունից օգտվելու
          ընթացքում մշակված տեղեկատվությանը: Օպերատորը չի վերահսկում և
          պատասխանատվություն չի կրում Երրորդ անձանց կայքերի կողմից
          տեղեկատվության մշակման համար, որոնց Օգտատերը կարող է հետևել
          Համակարգում առկա հղումների միջով:
        </p>
        <p>
          1.3. Օպերատորը չի ստուգում Օգտատիրոջ կողմից տրամադրված անձնական
          տվյալների արժանահավատությունը և չի կարող որոշել Օգտատիրոջ
          գործունակությունը: Օպերատորը առաջնորդվում է այն կանխավարկածով, որ
          Օգտատերը տրամադրում է ճշգրիտ անձնական տվյալներ և, ըստ անհրաժեշտության,
          այն թարմացնում է:
        </p>
        <p
          style={{
            marginBottom: "20px",
          }}
        >
          1․4․ Օպերատորը պատասխանատվություն չի կրում Օգտատիրոջ կողմից տրամադրված
          տեղեկատվության բովանդակության, ստույգության, օրինականության կամ
          արժանահավատության, ինչպես նաև երրորդ անձանց կողմից նշված
          տեղեկատվությունն ապօրինի օգտագործելու հնարավոր հետևանքների համար:
        </p>
        <p>2․ Օգտատերերի անձնական տեղեկատվության մշակման նպատակները</p>
        <p>
          2.1. Օպերատորը մշակում և պահպանում է Օգտատիրոջ միայն այն անձնական
          տվյալները, որոնք անհրաժեշտ են վերջինիս Համակարգում գրանցվելու,
          Համաձայնագիրը կնքելու (Օֆերտան ակցեպտավորելու) և Ծառայությունից
          օգտվելու համար, և այն ժամկետով, որն օբյեկտիվորեն անհրաժեշտ է տվյալներ
          մշակելու նպատակներն իրականացնելու համար, բացառությամբ այն դեպքերի, երբ
          օրենքը նախատեսում է որոշակի ժամկետով անձնական տվյալների պարտադիր
          պահպանում:
        </p>
        <p>
          2.2. Օպերատորը մշակում է Օգտատիրոջ անձնական տվյալները հետևյալ
          նպատակներով. Համակարգի Ծառայությունից օգտվելու շրջանակներում Օգտատիրոջ
          նույնականացում, Համաձայնագրի շրջանակներում Օգտատիրոջն անհատականացված
          Ծառայության տրամադրում,
        </p>
        <p>
          Օգտատիրոջ հետ հաղորդակցության ապահովում, այդ թվում՝ Ծառայությունների
          օգտագործման և Համաձայնագրի Պայմանաների կատարման վերաբերյալ
          ծանուցումների, հարցումների և տեղեկությունների ուղարկում,
        </p>
        <p>
          Ծառայության որակի բարելավում և օգտագործման հարմարավետություն, նոր
          ծառայությունների մշակում,
        </p>
        <p
          style={{
            marginBottom: "20px",
          }}
        >
          Անանուն տվյալների հիման վրա վիճակագրական և այլ հետազոտությունների
          անցկացում:
        </p>
        <p>
          3. Օգտատերերի անձնական տվյալների մշակման և երրորդ անձանց փոխանցման
          պայմանները
        </p>
        <p>
          3.1. Օպերատորը պահպանում է Օգտատերերի անձնական տվյալները սույն
          Գաղտնիության քաղաքականության, «Անձնական տվյալների պաշտպանության մասին»
          ՀՀ օրենքի և այլ իրավական ակտերի պահանջներին համապատասխան:
        </p>
        <p>
          3.2. Օգտատիրոջ անձնական տվյալների գաղտնիությունը պահպանվում է,
          բացառությամբ այն դեպքերի, երբ Օգտատերը կամավոր կերպով տեղեկատվություն
          է տրամադրում իր մասին անորոշ թվով անձանց ընդհանուր մուտքի համար:
        </p>
        <p>
          3.3. Օպերատորն իրավունք ունի Օգտատիրոջ անձնական տվյալները փոխանցել
          երրորդ կողմերին, երբ․
        </p>
        <p>Օգտատերը տվել է իր համաձայնությունը,</p>
        <p>
          Օգտատիրոջ անձնական տվյալների փոխանցումն անհրաժեշտ է Համաձայնագրի
          կատարման համար.
        </p>
        <p>
          Օգտատիրոջ անձնական տվյալների փոխանցումը նախատեսված է ՀՀ
          օրենսդրությամբ, Անհրաժեշտություն է առաջացել պաշտպանելու Օպերատորի կամ
          երրորդ անձանց իրավունքները և օրինական շահերը Օգտատիրոջ կողմից խախտվել
          են Համաձայնագրի Պայմանները, Քաղաքականությունը կամ Օպերատորի և
          Օգտատիրոջ հարաբերությունները կարգավորող այլ, այդ թվում՝ օրենսդրական
          նորմեր,
        </p>
        <p>
          Օգտատիրոջ անձնական տվյալների մշակման արդյունքում ստացված ապանձնավորված
          վիճակագրական տվյալներն անհրաժեշտ են Օպերատորին՝ հետազոտության համար:
        </p>
        <p
          style={{
            marginBottom: "20px",
          }}
        >
          3.4. Օգտատերերի անձնական տվյալները մշակելիս Օպերատորը ղեկավարվում է
          սույն Գաղտնիության քաղաքականությամբ, «Անձնական տվյալների պաշտպանության
          մասին» ՀՀ օրենքով և այլ իրավական ակտերով:
        </p>
        <p>
          4. Անձնական տվյալների փոփոխություն և ոչնչացում: Անձնական տվյալների
          պարտադիր պահպանում
        </p>
        <p>
          4.1. Օգտատերը ցանկացած պահի կարող է փոփոխել (թարմացնել, լրացնել) իր
          կողմից տրամադրված անձնական տվյալները կամ դրա մի մասը։
        </p>
        <p>
          4.2. Օգտատերը կարող է ոչնչացնել նաև իր տրամադրած անձնական տվյալները։
        </p>
        <p
          style={{
            marginBottom: "20px",
          }}
        >
          4.3. Քաղաքականությունով կարող է սահմանափակվել 4.1. և 4.2. կետերով
          նախատեսված իրավունքները՝ ՀՀ գործող օրենսդրության պահանջներին
          համապատասխան: Մասնավորապես, նման սահմանափակումները կարող են նախատեսվել
          Օպերատորի պարտավորությամբ՝ օրենքով սահմանված ժամկետներում պահպանելու
          Օգտատիրոջ կողմից փոփոխված կամ ոչնչացված տեղեկատվությունը և օրենքով
          սահմանված կարգով այն փոխանցելու պետական մարմնին:
        </p>
        <p>5. Թխուկ (cookie files) ֆայլերի օգտագործումը</p>
        <p
          style={{
            marginBottom: "20px",
          }}
        >
          Ըստ ԵՄ անձնական տվյալների պատպանության ընդհանուր կանոնակարգի (GDPR)
          (1) (6)-րդ հոդվածի, մենք օգտագործում ենք մեր վեբկայքում «թխուկ ֆայլեր»
          (cookie files) աշխատանքը օպտիմալացնելու համար: Թխուկ ֆայլերը փոքր
          տեքստային ֆայլեր են, որոնք ավտոմատ կերպով ստեղծվում են ձեր զննիչում
          (browser) և պահվում ձեր սարքում (օրինակ՝ դյուրակիր համակարգչում,
          գրասալիկում, սմարթֆոնում), երբ այցելում եք մեր կայք: Տեղեկատվությունը,
          որը ստեղծվում է ըստ օգտագործվող հատուկ սարքի, պահվում է թխուկ ֆայլում:
        </p>
        <p
          style={{
            marginBottom: "20px",
          }}
        >
          Սա չի նշանակում, որ մենք կկարողանանք ուղղակիորեն նույնականացնել ձեզ:
          Թխուկ ֆայլերը նախատեսված են ավելի հարմար դարձնելու մեր
          ծառայություններից օգտվելը: Մենք օգտագործում ենք սեանսային թխուկ
          ֆայլեր, օրինակ՝ որոշելու համար, որ դուք արդեն այցելել եք մեր կայքում
          առանձին էջեր: Մեր կայքից դուրս գալուց հետո թխուկ ֆայլերը ավտոմատ
          կերպով ջնջվում են:
        </p>
        <p
          style={{
            marginBottom: "20px",
          }}
        >
          Հարմարավետ օգտագործման համար մենք նաև օգտագործում ենք ժամանակավոր
          թխուկ ֆայլեր, որոնք պահվում են ձեր սարքի վրա որոշակի
          ժամանակահատվածում: Եթե ծառայություններից օգտվելու համար դուք կրկին
          այցելեք մեր կայք, ապա անմիջապես կհայտնաբերվի, որ դուք արդեն այցելել եք
          մեր կայքը, ինչ տվյալներ են մուտքագրվել ձեր կողմից և ինչ տեղադրումներ
          են կատարվել, ուստի, դուք ստիպված չեք լինի մուտքագրել այդ ամենը նորից:
        </p>
        <p>
          Շատ զննիչներ (browser) թխուկ ֆայլերը ընդունում են ավտոմատ կերպով: Դուք
          կարող եք հարմարեցնել ձեր զննիչի տեղադրումը այնպես, որ Ձեր սարքի վրա
          չպահպանվեն թխուկ ֆայլերը կամ տեղադրեք ծանուցում, որը կհայտնվի ամեն
          անգամ, երբ նոր թխուկ ֆայլեր տեղադրվեն: Սակայն, թխուկ ֆայլերի
          ամբողջական անջատումը կարող է հանգեցնել այն բանի, որ դուք չեք կարողանա
          լիարժեք չափով օգտագործել մեր կայքում եղած բոլոր գործառույթները:
        </p>
        <p>
          Օպերատորի կողմից Օգտատիրոջ սարքավորումներին և Օգտատիրոջ
          սարքավորումներին փոխանցվող թխուկները կարող են օգտագործվել Օպերատորի
          կողմից՝ Օգտատիրոջն անհատականացված Ծառայություններ տրամադրելու և
          Ծառայությունները բարելավելու նպատակներով վիճակագրական և հետազոտական
          կատարելու նպատակներով։
        </p>
        <p>
          Օգտատերը տեղյակ է, որ իր կողմից Ծառայությունից օգտվելու համար
          օգտագործվող սարքավորումները և ծրագրաշարը կարող են ունենալ թխուկների
          հետ գործառնություններն արգելելու, ինչպես նաև նախկինում ստացված
          թխուկները ջնջելու գործառույթ: Թխուկների կառուցվածքը, դրա
          բովանդակությունը և տեխնիկական պարամետրերը որոշվում են Ընկերության
          կողմից և կարող են փոխվել առանց Օգտատիրոջ նախնական ծանուցման:
        </p>
        <p
          style={{
            marginBottom: "20px",
          }}
        >
          Ծառայության մեջ տեղադրված հաշվիչները կարող են օգտագործվել Օգտատիրոջ
          թխուկները վերլուծելու, Ծառայությունների օգտագործման վերաբերյալ
          վիճակագրական տեղեկություններ հավաքելու և մշակելու, ինչպես նաև
          Ծառայությունների գործունեությունը ընդհանրապես կամ դրանց առանձին
          գործառույթները, մասնավորապես, ապահովելու համար: Հաշվիչների շահագործման
          տեխնիկական պարամետրերը որոշվում են Օպերատորի կողմից և կարող են փոխվել
          առանց Օգտատիրոջ նախնական ծանուցման:
        </p>
        <p
          style={{
            marginBottom: "20px",
          }}
        >
          6. Օգտատիրոջ անձնական տվյալների պաշտպանության համար ձեռնարկված
          միջոցներ Օպերատորը ձեռնարկում է անհրաժեշտ և բավարար կազմակերպչական և
          տեխնիկական միջոցներ՝ պաշտպանելու Օգտատիրոջ անձնական տվյալները
          չարտոնված կամ պատահական մուտքերից, ոչնչացումից, փոփոխումից,
          արգելափակումից, պատճենումից, տարածումից, ինչպես նաև երրորդ անձանց այլ
          անօրինական գործողություններից:
        </p>
        <p>
          7. Քաղաքականության մեջ փոփոխություններ և լրացումներ կատարելու կարգը
        </p>
        <p>
          7.1. Օպերատորն իրավունք ունի փոփոխություններ և լրացումներ կատարել
          Քաղաքականության մեջ: Քաղաքականության գործող տարբերակում
          փոփոխություններ և լրացումներ կատարելիս նշվում է վերջին թարմացման
          ամսաթիվը: Քաղաքականության նոր տարբերակն ուժի մեջ է մտնում հրապարակման
          պահից:
        </p>
        <p
          style={{
            marginBottom: "20px",
          }}
        >
          7.2. Քաղաքականությունը կիրառելու արդյունքում Օպերատորի և Օգտատիրոջ
          միջև ծագած հարաբերությունները կարգավորվում են ՀՀ օրենսդրությամբ:
        </p>
        <p>8. Հետադարձ կապ</p>
        <p
          style={{
            marginBottom: "20px",
          }}
        >
          Քաղաքականության վերաբերյալ բոլոր առաջարկները կամ հարցերը Օգտատերն
          կարող է ուղարկել Օպերատորի ——— էլեկտրոնային փոստին:
        </p>
        <p>
          9. Գաղտնիություն և անձնական տվյալների պաշտպանություն․ Ռիսկերի
          կառավարում
        </p>
        <p>
          9․1․ Օֆերտայի պայմաններով Համաձայնագիր կնքելով՝ Կողմերը պարտավորվում
          են.
        </p>
        <p>
          9.1․1․ Չհրապարակել Համաձայնագրի կատարման հետ կապված մյուս Կողմից
          ստացված գաղտնի տեղեկատվությունը, այդ թվում՝ Կողմի անձնական տվյալները։
          Ձեռնարկել բոլոր անհրաժեշտ միջոցները Ծառայության ընթացքում փոխանակված
          կամ Կողմերին Ծառայությունից օգտվելու ընթացքում հասանելի դարձած
          տեղեկատվությունն անվտանգ պայմաններում պահելու և պաշտպանելու համար:
        </p>
        <p>
          9.1.2. Ծառայության միջոցով չկատարել փողերի լվացման և ահաբեկչության
          ֆինանսավորմանն ուղղված անօրինական ֆինանսական գործարքներ, անօրինական
          առևտուր, ինչպես նաև ՀՀ օրենսդրությամբ արգելված այլ գործողություններ,
        </p>
        <p>
          9.1.3․ Կանխել ապօրինի առևտրի փորձերը, ապօրինի ֆինանսական գործարքները,
          հանցավոր ճանապարհով ստացված եկամուտների օրինականացման (փողերի լվացման)
          գործողությունները:
        </p>
        <p>
          9.1.4․ Կողմից ստացված գաղտնի տեղեկատվությունը չօգտագործվել որևէ այլ
          նպատակով՝ բացի Համաձայնագրի նպատակներից,
        </p>
        <p>
          9․1․5․ Համաձայնագրի նպատակների համար ողջամտորեն անհրաժեշտ քանակից
          ավելի չվերարտադրել, չպատճենել, չփոխանցել, չբացահայտել կամ այլ կերպ
          որևէ երրորդ կողմի հասանելի չդարձնել Կողմից ստացված գաղտնի
          տեղեկատվությունը՝ բացառությամբ օրենքով սահմանված դեպքերի,
        </p>
        <p>
          9.2. Քաղաքականության դրույթները չեն կիրառվում այն տեղեկատվության
          նկատմամբ, որոնք․
        </p>
        <p>
          9․2․1․Օրենքով նախատեսված են որպես հանրամատչելի տեղեկություններ, ինչպես
          նաև Կողմի համաձայնությամբ կամ իր անձնական տվյալները հանրամատչելի
          դարձնելուն ուղղված գիտակցված գործողությունների կատարմամբ մատչելի են
          դարձել որոշակի կամ անորոշ շրջանակի անձանց համար (հանրամատչելի անձնական
          տվյալներ),
        </p>
        <p
          style={{
            marginBottom: "20px",
          }}
        >
          9.2.2. Բացահայտվել են օրենքով կամ գործադիր մարմնի որևէ կարգավորող
          իրավական ակտի համաձայն։
        </p>
        <p>
          10․ Օպերատորը հետևում և ապահովում է, որ Օգտատերերի անձնական տվյալները
          մշակվեն ՀՀ օրենսդրության պահանջների պահպանմամբ: Ապահովել Օգտատիրոջ
          անձնական տվյալների, նրա հաշվից կատարված Վճարումների և այլ
          տեղեկատվության գաղտնիությունը, որոնք Օպերատորին հայտնի են դարձել
          Ծառայությունից օգտվելու հետ կապված: Օպերատորը Օգտատիրոջ վերաբերյալ
          տեղեկատվությունը կարող է բացահայտել և օգտագործվել այլ նպատակներով
          միայն Օգտատիրոջ համաձայնությամբ կամ կարգադրությամբ, բացառությամբ
          օրենքով սահմանված այն դեպքերի, երբ.
        </p>
        <p>
          • տեղեկատվությունը պահանջվում է հրապարակել օրենքով նախատեսված հիմքերով
          կամ կասկածելի գործարքների վերաբերյալ կամ դատարանից կամ լիազորված
          պետական մարմիններից համապատասխան հարցումներ ստանալուց հետո,
        </p>
        <p>
          • դա անհրաժեշտ է Ծառայությունների մատուցման համար (Օպերատորն իրավունք
          ունի Վճարում ստացող անձին բացահայտել Վճարողի անձնական տվյալները և
          վճարման գործարքին վերաբերող մանրամասնությունները),
        </p>
        <p>
          • դա անհրաժեշտ է Օպերատորի խախտված իրավունքների պաշտպանության (այդ
          թվում՝ դատական) համար,
        </p>
        <p>
          • նման տեղեկատվությունը հասանելի է հանրությանը և օրենքով նախատեսված է
          որպես հանրամատչելի տեղեկություն:
        </p>
        <p>
          Օպերատորը պահպանում է Համակարգի միջոցով կատարված գործարքների
          վերաբերյալ տեղեկությունները Համաձայնագիրը լուծելու օրվանից հաշված 3
          (երեք) տարի:
        </p>
        <p>
          Օպերատորը կարող է Գաղտնիության, տեղեկատվության պահպանման և
          անվտանգության ապահովման նպատակով՝ ցանկացած պահի իրականացնել
          Ծառայության տեխնիկական սպասարկման կանխարգելիչ և այլ աշխատանքներ՝
          սահմանափակելով Օգտատիրոջ մուտքը Ծառայություն:
        </p>
        <p
          style={{
            width:'160px',
            fontSize: "15px",
            fontWeight: "400",
            fontFamily: "ui-monospace",
            margin: "70px auto",
          }}
        >
          2022 Ստեպպերս Օրիոն
        </p>
      </div>
    </div>
  );
};

export default Privacy;
