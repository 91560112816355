import React from "react"

const Guest = ({language}) => {
  return (
    <div className="Guest">
      <div>
        <img style={{marginTop:'-12px'}} src={require("../components/imgs/check.png")} alt="check" />
        <p style={{marginTop:'16px'}}>
            {language.block2Guest1}
        </p>
      </div>

      <img className="arrow" src={require("../components/imgs/Arrow.png")} alt="arrowImg" />
      
      <div>
        <img src={require("../components/imgs/guestTel1.png")} alt="Phone" />
        <p>
            {language.block2Guest2}
        </p>
      </div>
      
      <img className="arrow" src={require("../components/imgs/Arrow.png")} alt="arrowImg" />
      
      <div>
        <img src={require("../components/imgs/guestTel2.png")} alt="Phone" />
        <p>
            {language.block2Guest3}
        </p>
      </div>
    </div>
  )
};

export default Guest;
