import { useState } from "react";
import axios from "axios";
import "../components.scss";
import { TextField } from "@mui/material";
import tipsAM from '../imgs/forgotTipsAm.svg'

function DeleteAccountPage() {
  let emailReg = /^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  const [checkBox1, setCheckBox1] = useState(false);
  const [checkBox2, setCheckBox2] = useState(false);
  const [checkBox3, setCheckBox3] = useState(false);
  const [checkBox4, setCheckBox4] = useState(false);
  const [checkBox5, setCheckBox5] = useState(false);
  const [isFilled,setIsFilled] = useState(true)
  const checkBox1Text = "I no longer use this app/service";
  const checkBox2Text = "I am concerned about my privacy";
  const checkBox3Text = "I did not find the app/service useful";
  const checkBox4Text = "I found a better alternative";
  const checkBox5Text = "other";
  const [email, setEmail] = useState("");
  const [otherMessage, setOtherMessage] = useState("");

  const allToFalse = () => {
    setCheckBox1(false);
    setCheckBox2(false);
    setCheckBox3(false);
    setCheckBox4(false);
    setCheckBox5(false);
    setEmail("");
    setOtherMessage("");
  };
  let reason = [];
  if (checkBox1) {
    reason.push(checkBox1Text);
  }
  if (checkBox2) {
    reason.push(checkBox2Text);
  }
  if (checkBox3) {
    reason.push(checkBox3Text);
  }
  if (checkBox4) {
    reason.push(checkBox4Text);
  }
  if (checkBox5 && otherMessage.trim().length) {
    reason.push(otherMessage.trim());
  }

  const sendData = async (e) => {
    e.preventDefault();
    if (
      email.trim() &&
      emailReg.test(email) &&
      (checkBox1 || checkBox2 || checkBox3 || checkBox4 || checkBox5)
    ) {
      try {
        const response = await axios.post(
          "https://api.tips.am/api/users/email/unsubscribe",
          { email, reason }
        );
        console.log(response.data);
        allToFalse();
      } catch (error) {
        console.log("err");
      }
    }else{

    }
    if(email.trim().length && emailReg.test(email)){
      setIsFilled(true)
    }else{
      setIsFilled(false)
    }
  };

  return (
    <div className="unSubscribePage">
      <div className="Box">
        <img src={tipsAM} alt="Tips.am" />
        <div className="emailInput">
          <p>enter your email</p>
          <TextField
            fullWidth
            label=""
            id="fullWidth"
            error={!isFilled}
            required
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
        <form className="checkBoxes" onSubmit={sendData}>
          <p
              style={{
                marginBottom: "25px",
                marginTop: "0px",
                fontWeight: "300",
              }}
          >Note: By submitting this form, you request the deletion of your account and all associated data. This
            process may take up to [7 days].</p>
          <p
              style={{
                marginBottom: "25px",
                marginTop: "0px",
                fontWeight: "600",
              }}
          >
            Why do you want to unsubscribe from Tips.am?
          </p>

          <div className="checkBoxLists">
            <input
                type="checkbox"
                checked={checkBox1}
                onChange={() => setCheckBox1(!checkBox1)}
            />
            <p>{checkBox1Text}</p>
          </div>
          <div className="checkBoxLists">
            <input
                type="checkbox"
                checked={checkBox2}
                onChange={() => setCheckBox2(!checkBox2)}
            />
            <p>{checkBox2Text}</p>
          </div>
          <div className="checkBoxLists">
            <input
                type="checkbox"
                checked={checkBox3}
                onChange={() => setCheckBox3(!checkBox3)}
            />
            <p>{checkBox3Text}</p>
          </div>
          <div className="checkBoxLists">
            <input
                type="checkbox"
                checked={checkBox4}
                onChange={() => setCheckBox4(!checkBox4)}
            />
            <p>{checkBox4Text}</p>
          </div>
          <div className="checkBoxLists">
            <input
                type="checkbox"
                checked={checkBox5}
                onChange={() => setCheckBox5(!checkBox5)}
            />
            <p>{checkBox5Text}</p>
          </div>
          {checkBox5 && (
              <textarea
                  type="text"
                  className="otherInput"
                  value={otherMessage}
                  disabled={!checkBox5}
                  onChan
                  ge={(e) => setOtherMessage(e.target.value)}
              />
          )}
          <button>Submit Deletion Request</button>
        </form>
      </div>
    </div>
  );
}

export default DeleteAccountPage;
