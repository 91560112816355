import CardConnectStatus from "../components/cardConnectStatus/cardConnectStatus";
import ConfirmPage from "../components/confirm/confirmPage";
import PasswordForgotPage from "../components/forgot/passwordForgotPage";
import MainPages from "../components/mainPages";
import Privacy from "../components/privacy/privacy";
import Terms from "../components/terms/terms";
import UnSubscribePage from "../components/unsubscribe/unSubscribePage";
import DeleteAccountPage from "../components/delete-account/deleteAccountPage";

export const MAIN_PAGE = "*";
export const UNSUBSCRIBE_PAGE = "/unsubscribe";
export const FORGOT_PASSWORD_PAGE = "/forgot/:token";
export const CONFIRM_PAGE = "/email/confirm/:token";
export const CARD_CONNECT_PAGE = "/bind";
export const TERMS_PAGE = "/terms";
export const PRIVACY_PAGE = "/privacy";
export const GUEST_PAGE = "/guest";
export const EMPLOYEE_PAGE = "/employee";
export const INSTITUTION_PAGE = "/institution";
export const DELETE_ACCOUNT_PAGE = "/delete-account";

export const routes = [
  { id: 1, name: "main", path: MAIN_PAGE, element: <MainPages /> },
  { id: 2, name: "unsubscribe",path: UNSUBSCRIBE_PAGE,element: <UnSubscribePage />},
  { id: 3, name: "forgot",path: FORGOT_PASSWORD_PAGE,element: <PasswordForgotPage />},
  { id: 4, name: "confirm", path: CONFIRM_PAGE, element: <ConfirmPage /> },
  { id: 5, name: "privacy", path: PRIVACY_PAGE, element: <Privacy /> },
  { id: 6, name: "terms", path: TERMS_PAGE, element: <Terms /> },
  { id: 7, name: "card connect status", path: CARD_CONNECT_PAGE, element: <CardConnectStatus /> },
  { id: 8, name: "delete-account",path: DELETE_ACCOUNT_PAGE,element: <DeleteAccountPage />},
];
