import React, { useState } from "react"
import { useEffect } from "react";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import './components.scss'
import Guest from "../utils/Guest";
import Employee from "../utils/Employee";
import Institution from "../utils/Institution";

const HowDoesItWork = ({language}) => {
  const navigate = useNavigate()
  const [activeIndex, setActiveIndex] = useState(1);
  useEffect(() => {
    navigate(activeIndex == 1 ? "/" : activeIndex == 2 ? "/employee" : "institution")
  }, []);

  const route1 = [
    { id: 1, name: language.block2btn1, path: "/", element: <Guest language={language}/> },
    { id: 2, name: language.block2btn2, path: '/employee', element: <Employee language={language}/> },
    { id: 3, name: language.block2btn3, path: '/institution', element: <Institution language={language}/> },
  ]
  return (
    <div id="howDoesItWork" className="howDoesItWork">
      <h1>
          {language?.block2Text1}
      </h1>
      <div className="menuDiv">
        {
          route1.map(({ name, path, id }) => {
            return  <div key={path}>
              <Link to={path} exact="true">
                <div className={activeIndex === id ? 'divActive' : 'divUnactive'} onClick={() => {
                  setActiveIndex(id)
                }}>
                  {name}
                </div>
              </Link>
            </div>
          })
        }
      </div>
      <div className="routeDiv">
        <Routes>
          {
            route1.map(({ path, element }) => {
              return <Route key={path} path={path} element={element} exact="true" />
            })
          }
        </Routes>
      </div>

    </div>
  )
};

export default HowDoesItWork;
