import React from "react"
import "./components.scss"
import FeedBackDialog from "./feedBackDialog";
import {Texts} from "../utils/Texts";

const Tip = ({language}) => {

    const greenBgImgStyle = {
        width: '750px',
        right: '0px',
        top: '-100px',
        transition: '.5s'
    }
    const purpleBgImgStyle = {
        width: '740px',
        right: '0px',
        top: '-100px',
        transition: '.5s'
    }
    const phoneImgStyle = {
        width: '520px',
        right: '150px',
        transition: '.5s'
    }
    return (
        <div className="Tip">
            <div className="tipBox">
                <h1>
                    {language.block1Text1}
                </h1>
                <p>
                    {language.block1Text2}
                </p>
                <div className="buttons">
                    <FeedBackDialog text={language?.block1btn1} language={language} />
                    <a href="#" style={{cursor:"pointer"}} target="_blank">
                        <img style={{
                            marginRight: '15px'
                        }} src={require("./imgs/Polygon3.png")} alt="polygon" /> {language.block1btn2}
                    </a>
                </div>
            </div>
            <div className="images">
                <img className="BgGreen"
                    style={greenBgImgStyle} src={require("./imgs/RectangleGreen.png")} alt="rectangleImg" />
                <img className="BgPurple"
                    style={purpleBgImgStyle} src={require("./imgs/RectanglePurple.png")} alt="rectangleImg" />
                <img className="PhoneImg"
                    style={phoneImgStyle} src={require("./imgs/newPhones.png")} alt="Phone" />
            </div>
        </div>

    )
};

export default Tip;
